<template>
  <div class="main">
    <el-container>
      <el-header>
        <div class="d-flex header align-items-center justify-content-between">
          <div class="d-flex align-items-center header-login">
            <div v-if="IsPC">
              <img
                class="ml-3"
                width="116px"
                src="../assets/images/logo.png"
                alt=""
              />
            </div>
            <div v-else>
              <img
                class="ml-2"
                width="40px"
                src="../assets/images/mobile-logo.png" 
                alt=""
              />
            </div>
            <h4 class="pl-5 fs-6 ml-4">师资管理系统</h4>
          </div>

          <div class="menu" v-if="!IsPC">
            <!-- <mobile-nav /> -->
            <span
              v-if="isStaff"
              @click="$router.push('/')"
              class="mr-3"
              :class="{ menuActive: $route.path == '/' }"
              >首页</span
            >
            <span
              @click="$router.push('/customers/list')"
              class="mr-3"
              v-if="isStaff"
              :class="{ menuActive: $route.path == '/customers/list' }"
              >客户管理</span
            >
            <span
              class="mr-3"
              @click="$router.push('/schedules/list')"
              :class="{ menuActive: $route.path == '/schedules/list' }"
              >档期管理</span
            >
            <span
              v-if="isStaff"
              @click="$router.push('/teachers/list')"
              :class="{ menuActive: $route.path == '/teachers/list' }"
              >讲师管理</span
            >
            <!-- <span
              v-if="isStaff"
              @click="$router.push('/courseware/list')"
              :class="{ menuActive: $route.path == '/courseware/list' }"
              >资料管理</span
            > -->
          </div>

          <div class="d-flex align-items-center">
            <el-popover placement="bottom-start" width="160" trigger="hover">
              <div class="avatar d-flex align-items-center" v-if="user" slot="reference">
                <el-avatar :size="30" :src="user.avatar"></el-avatar>
                <span class="pl-1 realname" style="font-size: 16px">{{
                  user.realname
                }}</span>

                <span class="role">（{{ identity }}）</span>
                <i class="el-icon-caret-bottom text-gray"></i>
              </div>
              <div>
                <div
                  class="
                    d-flex
                    justify-content-between
                    align-items-center
                    cursor-pointer
                    border-bottom
                    pb-2
                  "
                  @click="changePw"
                >
                  <div>
                    <i
                      class="iconfont icon-xiugaimima pr-4"
                      style="width: 40px"
                    ></i>
                    <span>修改密码</span>
                  </div>
                  <i class="el-icon-arrow-right"></i>
                </div>
                <div class="d-flex cursor-pointer pt-3" @click="loginOut">
                  <i
                    class="iconfont icon-tuichudenglu pr-2"
                    style="width: 40px"
                  ></i>
                  <span>退出登录</span>
                </div>
              </div>
            </el-popover>
          </div>
        </div>
      </el-header>

      <el-container style="height: 92vh">
        <el-aside width="150px">
          <el-menu
            class="el-menu-vertical-demo"
            mode="vertical"
            :default-active="$route.path"
            router
          >
            <el-menu-item
              v-for="item in navMenu"
              :key="`menu-item-${item.path}`"
              :index="item.path"
            >
              <div>
                <i class="pr-3" :class="item.icon"></i>
                <span>{{ item.title }}</span>
              </div>
            </el-menu-item>
          </el-menu>
          <!-- <template slot="title">{{ item.title }}</template> -->
        </el-aside>
        <el-main>
          <!-- <keep-alive include="cache">
            <router-view></router-view>
          </keep-alive> -->
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive && !$route.query.new">
              <!-- 这里是会被缓存的视图组件，比如 Home！ -->
            </router-view>
          </keep-alive>

          <router-view v-if="!$route.meta.keepAlive || $route.query.new">
            <!-- 这里是不被缓存的视图组件，比如 Edit！ -->
          </router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
// import mobileNav from '../components/mobileNav.vue';
export default {
  // components: { mobileNav },
  data() {
    return {
      form: {
        target: null,
      },
      visible: false,
      navItems: [],
      menu: {
        items: [
          {
            icon: "iconfont icon-shouye1",
            title: "工作台",
            path: "/",
            roles: [0, 1, 4, 5, 8],
          },
          // {
          //   title: "联系人",
          //   path: "/contacts/list",
          // },

          {
            title: "客户管理",
            path: "/customers/list",
            icon: "iconfont icon-kehu",
            roles: [1, 0, 4, 5, 6],
          },
          // {
          //   title: "客户公共池",
          //   path: "/commonPool/list",
          //   icon: "iconfont icon-gonggongkehu",
          //   roles: [1, 0, 4, 5],
          // },
          {
            title: "跟进情况",
            path: "/followUp/list",
            icon: "iconfont icon-kehugenjin",
            roles: [1, 0, 4, 5],
          },

          {
            title: "档期管理",
            path: "/schedules/list",
            icon: "iconfont icon-rili",
            roles: [0, 1, 2, 3, 4, 5, 6, 7, 8],
          },
          {
            title: "资料管理",
            path: "/courseware/list",
            icon: "iconfont icon-kejian-01",
            roles: [0, 1, 4, 5, 7, 8,9],
          },
          
         
          {
            title: "档期报备",
            path: "/schedules/report",
            icon: "iconfont icon-baobei",
            roles: [0, 1, 4, 5],
          },
         

          {
            title: "讲师管理",
            path: "/teachers/list",
            icon: "iconfont icon-jiangshi",
            roles: [1, 0, 4, 5, 6, 7],
          },
          {
            title: "师资开发",
            path: "/develop_teacher/list",
            icon: "iconfont icon-shizibaozhang",
            roles: [0],
          },
          {
            title: "合同管理",
            path: "/contract/list",
            icon: "iconfont icon-hetong",
            roles: [0, 1, 4, 5, 8],
          },
        
          {
            title: "财务模块",
            path: "/finance/list",
            icon: "iconfont icon-caidancaiwu",
            roles: [0,8,1,4,5],
          },
          {
            title: "催款档期",
            path: "/dunning/list",
            icon: "iconfont icon-cuikuanhan",
            roles: [0, 1, 4, 5,8],
          },
          // {
          //   title: "商机管理",
          //   path: "/business/list",
          // },
          // {
          //   title: "联系人",
          //   path: "/contacts/list",
          // },

          {
            title: "其他业务",
            path: "/sale_contract/list",
            icon: "iconfont icon-bianzu8",
            roles: [0, 1, 4, 5, 8],
          },
          {
            title: "开票信息",
            path: "/invoice/list",
            icon: "iconfont icon-fapiao1",
            roles: [0, 1, 4, 5, 8],
          },
           {
            title: "开票管理",
            path: "/invoice_manage2/list",
            icon: "iconfont icon-kehukaipiaoguanli",
            roles: [0, 8],
          },
         
          {
            title: "账号管理",
            path: "/admin_user/list",
            icon: "iconfont icon-yonghuguanli",
            roles: [0],
          },

          {
            title: "选项管理",
            path: "/dictionaries/list",
            icon: "iconfont icon-zidianguanli1",
            roles: [0],
          },
          {
            title: "客户黑名单",
            path: "/customers/blacklist",
            icon: "iconfont icon-heimingdan",
            roles: [0, 1, 4, 6, 7,5],
          },
          {
            title: "用户行为",
            path: "/behavior/list",
            icon: "iconfont icon-yonghuhangweifenxi",
            roles: [0],
          },
        ],
      },
    };
  },
  computed: {
    navMenu() {
      return this.menu.items.filter((v) => v.roles?.includes(this.user.role));
    },
    identity() {
      const roles = {
        0: "管理员",
        1: "经纪人",
        2: "讲师",
        3: "其他讲师",
        4: "助理",
        5: "组长",
        6: "师资管理",
        7: "兼职",
        8: "财务",
        9: "客户",
      };
      return roles[this.user.role];
    },
  },
  methods: {
    cancel() {
      this.visible = false;
    },
    async save() {
      this.user.target_value = this.user.target;
      await this.$http.put(`users/${this.user._id}`, this.user);
      this.$message.success("设置成功");
      this.visible = false;
    },
    changePw() {
      this.$router.push("/change_pw");
    },
    async loginOut() {
      const params={
        user:this.user._id,
        action:"logout",
      }
      await  this.addBehavior(params)
      localStorage.setItem("user", "");
      localStorage.setItem("token", "");
      localStorage.setItem("teacherId", '');
      this.$router.push("/login");
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
  created() {
    // this.fetch_user();
    this.$store.dispatch("fetchUser");
    this.$store.dispatch("fetchCity");
    // this.thisNavItems();
  },
};
</script>

<style>
@media only screen and (max-width: 667px) {
  .avatar .realname {
    font-size: 12px !important;
  }
  .target {
    margin-right: 1rem !important;
    padding: 4px 8px !important;
  }
  .avatar .role {
    font-size: 12px !important;
    display: none;
  }
  .avatar img {
    width: 26px;
  }
  .el-popup-parent--hidden {
    padding-right: 0 !important;
  }
  .v-modal {
    z-index: 996 !important;
  }
  .px-4 {
    padding: 0 !important;
  }
  .el-aside {
    display: none;
  }
  h4 {
    display: none;
  }
  .el-main {
    padding: 0 10px !important;
  }
  .el-popover__reference {
    font-size: 10px !important;
  }
  .header-login img {
    width: 30px !important;
  }
  .header-login {
    padding-left: 0rem !important;
  }
}
.header-login {
  padding-left: 1.5rem;
}
.el-header {
  padding: 0 10px !important;
  padding-left: 0 !important;
  background-color: #fff;
  position: sticky;
  top: 0;
  color: #000;
  z-index: 996;
  box-shadow: 0 -1px 6px 0 rgba(0, 0, 0, 0.04),
    0 10px 20px 0 rgba(0, 0, 0, 0.04);
}

.el-menu {
  height: 90vh;
  overflow: auto;
}
.el-menu-item {
  transition: none !important;
}
.el-menu-item.is-active {
  color: #409eff !important;
  background-color: #f3f9ff !important;
  border-right: 3px solid #409eff;
}
.el-menu-item:focus,
.el-menu-item:hover {
  background-color: #f3f9ff !important;
}
.header {
  font-size: 0.9rem;
  color: #000;
  height: 60px;
}

.el-aside {
  overflow: hidden !important;
}

.target {
  border: 1px solid #ccc;
  border-radius: 1rem;
  padding: 6px 14px;
  margin-right: 3rem;
}
.el-main {
  padding: 10px 0px !important;
}

.menu {
  color: #888;
  font-size: 12px;
  /* height: 100%; */
}

.menuActive {
  color: #1989fa;
  border-bottom: 2px solid #1989fa;
  padding-bottom: 1.2rem;
  font-weight: 600;
  opacity: 0.6;
}
</style>
