<template>
  <div class="d-flex elcalendar w-100 h-100 " v-loading="schedule_loading" element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)" :class="{
      'p-3': IsPC
    }">
    <!-- <icon-menu></icon-menu> -->
    <el-row :gutter="0" class="w-100">
      <el-col :span="2" :xl="2" :lg="2" :md="4" class="teacherList">
        <div class="teacherList w-100" v-if="isTeacher">
          <div class="w-100">
            <div class="fs-9 p-2 cursor-pointer" @click="all">
              <i class="iconfont icon-jiangshi fs-9 pr-1"></i>
              <span>所有讲师</span>
            </div>
            <el-tree :data="teacherList" ref="tree" node-key="value" lazy :load="loadNode" :props="{
              isLeaf: 'leaf',
            }" @node-click="handleNodeClick"></el-tree>
          </div>
        </div>
      </el-col>

      <el-col :span="22" :xl="22" :lg="22" :md="20" :xs="24" style="overflow: hidden" class="ca-list"
        :class="{ 'w-100': !isTeacher }">
        <div class="w-100 " :class="{ pl: !isTeacher, 'px-3': IsPC }">
          <div class="tabel schdule_board " :class="{'h-100':!IsPC}">
            <import class="import fs-9" :doImport="doImport" :parseItems="parseItems"></import>
            <div class="d-flex justify-content-start align-items-center state-head" :class="{ 'flex-wrap': !IsPC }">
              <div class="d-flex align-items-center align-items-center" :class="{ 'pl-2': IsPC, 'mt-2': !IsPC }">
                <div class="d-flex w-100 query">
                  <div>
                    <div class="d-flex teacher align-items-center">
                      <div class="d-flex align-items-center mr-1" :class="{ 'pl-1': IsPC, 'ml-2': IsPC }"
                        v-if="user.role == 1 && !IsPC">
                        <div class="pr-0 border-radio d-flex justify-content-start" v-if="isTeacher">
                          <div class="d-flex">
                            <el-radio v-model="permission" :label="1" border class="m-0" size="mini">全部
                            </el-radio>
                            <el-radio v-model="permission" :label="2" border class="m-0" size="mini">仅看自己</el-radio>

                            <el-radio v-if="user.role == 5" v-model="permission" :label="3" border>仅看团队</el-radio>
                          </div>
                          <div class="px-3" v-if="permission == 3 && user.role == 5">
                            <el-cascader placeholder="请选择团队成员" v-model="members" :options="groups" :props="props"
                              clearable></el-cascader>
                          </div>
                        </div>

                        <div v-if="this.user.role < 2 || this.user.role > 3" class="exportSchedule">
                          <el-button type="success" plain @click="exportSchedule">导出档期</el-button>
                        </div>
                      </div>
                      <div class="d-flex align-items-center teacher_filter" v-if="isTeacher">
                        <div class="teacher-option fs-10 text-nowrap">
                          {{ IsPC ? "选择讲师：" : "讲师：" }}
                        </div>
                        <div>
                          <el-select filterable v-model="teacherId" placeholder="请选择" clearable style="width: 100px"
                            @change="setTeacher">
                            <el-option v-for="item in teacherOption" :key="item._id" :label="item.name"
                              :value="item._id"
                              filterable 
                              ></el-option>
                          </el-select>
                        </div>
                      </div>
                      <div v-else class="mr-3 fs-10" style="min-width: 6rem">
                        <span>讲师：</span>
                        <span>{{ lecturer.name }}</span>
                      </div>
                      <div class="d-flex add_sche" :class="{ 'w-100': !isTeacher }">
                        <div class="pl-2 d-flex justify-content-between w-100">
                          <div class="mr-2">
                            <el-button @click="scheduleClick" type="primary">
                              {{ IsPC ? "新增档期" : "新增" }}
                            </el-button>
                          </div>

                          <div class="pc_p d-flex align-items-center" v-if="user.role == 0 && !IsPC">
                            <div class="teacher-option text-nowrap ali fs-10">
                              负责人：
                            </div>
                            <div>
                              <el-select filterable clearable style="width: 100px" placeholder="选择负责人" v-model="agentId"
                                size="mini">
                                <el-option v-for="item in users" :key="item._id" :label="item.realname"
                                  :value="item._id" />
                              </el-select>
                            </div>
                          </div>
                          <div @click="refresh" class="refresh pr-2" v-if="!IsPC && !isTeacher">
                            <el-tooltip class="item" effect="dark" content="刷新" placement="left">
                              <button type="button"
                                class="el-button el-tooltip el-button--default el-button--mini is-circle"
                                aria-describedby="el-tooltip-4985" tabindex="0">
                                <i class="el-icon-refresh"></i>
                              </button>
                            </el-tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center status_filter"
                :class="{ 'w-100': !IsPC, 'pl-2': IsPC, 'mt-2': !IsPC }">
                <div class="d-flex state align-items-center" v-if="showStatus">
                  <div class="teacher-option ali fs-10 text-nowrap">状态：</div>
                  <div>
                    <el-select ref="select" v-model="scheduleState" placeholder="请选择" style="width: 100px">
                      <el-option width="60px" v-for="item in state" :key="item.value" :label="item.label"
                        :value="item.value"></el-option>
                    </el-select>
                  </div>
                </div>

                <div class="d-flex state align-items-center" :class="{ 'pl-3': IsPC, 'mt-2': !IsPC }"
                  v-if="user.role == 0 && IsPC">
                  <div class="teacher-option text-nowrap ali fs-10">
                    负责人：
                  </div>
                  <div>
                    <el-select filterable clearable style="width: 100px" placeholder="选择负责人" v-model="agentId"
                      size="mini">
                      <el-option v-for="item in users" :key="item._id" :label="item.realname" :value="item._id" />
                    </el-select>
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center filter_radio" :class="{ 'pl-3': IsPC, 'mt-2': !IsPC, 'ml-2': IsPC }"
                v-if="isShowUi">
                <div class="pr-2 border-radio d-flex justify-content-start" v-if="isTeacher">
                  <div class="d-flex">
                    <el-radio v-model="permission" :label="1" border class="m-0">全部
                    </el-radio>
                    <el-radio v-model="permission" :label="2" border class="m-0">仅看自己</el-radio>
                    <el-radio v-if="user.role == 5" v-model="permission" :label="3" border>仅看团队</el-radio>
                  </div>
                  <div class="px-3" v-if="permission == 3 && user.role == 5">
                    <el-cascader placeholder="请选择团队成员" v-model="members" :options="groups" :props="props"
                      clearable></el-cascader>
                  </div>
                  <div @click="refresh" class="refresh ml-2" v-if="!IsPC">
                    <el-tooltip class="item" effect="dark" content="刷新" placement="left">
                      <button type="button" class="el-button el-tooltip el-button--default el-button--mini is-circle"
                        aria-describedby="el-tooltip-4985" tabindex="0">
                        <i class="el-icon-refresh"></i>
                      </button>
                    </el-tooltip>
                  </div>
                </div>

                <div v-if="this.user.role < 2 || this.user.role > 3" class="exportSchedule">
                  <el-button type="success" plain @click="exportSchedule">导出档期</el-button>
                </div>

                <div @click="refresh" class="refresh mx-2" v-if="IsPC">
                  <el-tooltip class="item" effect="dark" content="刷新" placement="left">
                    <button type="button" class="el-button el-tooltip el-button--default el-button--mini is-circle"
                      aria-describedby="el-tooltip-4985" tabindex="0">
                      <i class="el-icon-refresh"></i>
                    </button>
                  </el-tooltip>
                </div>
              </div>
              <div class="d-flex align-items-center ml-auto justify-content-end show_filter">
                <div class="display-mode text-nowrap ml-2 fs-10" v-if="showRadio">
                  显示模式：
                </div>
                <el-radio-group v-model="isCalendar" style="width: 100px" class="d-flex">
                  <el-radio class="mr-3" :label="false" v-if="showRadio">表格</el-radio>
                  <el-radio :label="true" v-if="showRadio">日历</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div v-if="IsPC">
              <div class="trip text d-flex align-items-center">
                <div class="text-primary2 fs-9 d-flex align-items-center">
                  <i class="iconfont icon-xuzhi1 pr-1"></i>
                  <span class="ws-n">重要提醒：</span>
                </div>
                <div class="d-flex justify-content-between w-100">
                  <div class="fs-9 pt-1" style="width: 90%">
                    <ExpandText :lineClamp="1" v-if="lecturer.importantReminder" :info="teacherId ? lecturer.importantReminder || '无' : '无'
                      " />
                    <span class="mx-2" v-else>无</span>
                    <span v-if="!IsPC && !lecturer.importantReminder">(此内容在pc端填写)</span>
                  </div>
                </div>


              </div>
              <div class="w-100" v-if="user.teacher&&IsPC">
                <teacherData :user_id="user.teacher" />
              </div>


            </div>

            <div class="text-right mt-3" v-if="isCalendar"  :class="{'h-100':!IsPC}">
              <div class="date_top w-100 d-flex">
                <span class="time_str" v-if="IsPC"> {{ now_date }}</span>
                <el-date-picker style="width: 120px" v-model="value" type="month" :clearable="false"
                  value-format="yyyy-MM" placeholder="请选择月份" popper-class="date-picker_custom" class="mr-2"
                  :class="{ 'ml-auto': IsPC }" />

                <div class="select_times" :class="{ 'ml-auto': !IsPC }">
                  <el-button-group>
                    <el-button @click="setDate(item)" size="mini" v-for="item of cur_select_times" :key="item.value">{{
                      item.label }}</el-button>
                  </el-button-group>
                </div>
              </div>
              <el-calendar v-model="value" class="schedule_calendar"  :class="{'h-100':!IsPC}">
                <!-- dateCell是自定义每天的展示方式 -->
                <template #dateCell="{ date }">
                  <div @click="scheduleClick(date)">{{ date.getDate() }}</div>
                  <div v-if="indexed[dayjs(date).format('YYYYMMDD')]">
                    <div v-for="item in indexed[dayjs(date).format('YYYYMMDD')]"
                      :key="`${date.toString()}-${item._id}`">
                      <!-- <el-popover
                ref="popover"
                placement="right-start"
                trigger="hover"
                width="300"
                slot="reference"
                >-->

                      <div @click="scheduleClick(date, item)">
                        <el-tag size="mini" class="d-block mb-1" @click.stop="show(item)" :type="colour(item)">
                          <div class="tag" v-if="item.status < 10">
                            <div>{{ dayTime(item.createdAt) }}</div>

                            <div class="night">
                              <img v-if="
                                item.nights &&
                                item.nights.find((d) => {
                                  return (
                                    dayjs(d).format('YYYYMMDD') ===
                                    dayjs(date).format('YYYYMMDD')
                                  );
                                })
                              " src="../../assets/images/night.png" width="15" alt />
                            </div>
                            <span v-if="Array.isArray(item.city)">{{ item.city && item.city.join(",") }},</span>
                            <span v-else>{{ item.city }},</span>
                            <span>{{ getStateName(item.status) }},</span>
                            <span>{{ _.get(item, "teacher.name") }},</span>
                          </div>
                          <div class="tag" v-else>
                            <div>{{ dayTime(item.createdAt) }}</div>
                            <div class="night">
                              <img v-if="
                                item.nights &&
                                item.nights.find((d) => {
                                  return (
                                    dayjs(d).format('YYYYMMDD') ===
                                    dayjs(date).format('YYYYMMDD')
                                  );
                                })
                              " src="../../assets/images/night.png" width="15" alt />
                            </div>
                            <span>{{ getStateName(item.status) }},</span>
                            <span v-if="item.teacher && item.teacher.name">{{ _.get(item, "teacher.name") }},</span>
                            <span>{{ item.matter }}</span>
                          </div>
                        </el-tag>
                      </div>

                      <!-- </el-popover> -->
                    </div>
                  </div>

                  <!-- <el-tag
                    class="holiday_tag"
                    size="mini"
                    color="#dec674"
                    v-if="isFestival(date)"
                  >
                    <div
                      :class="isFestival(date) ? 'festival-holiday tag' : ''"
                      class=""
                    >
                      <span v-html="solarToLunar(date)"></span>
                      <span>{{ dayjs(date).format("YYYY-MM-DD") }} </span>
                    </div>
                  </el-tag> -->
                </template>
              </el-calendar>

              <!-- <div class="trip text d-flex align-items-center mt-2">
                <div class="text-primary2 fs-9 d-flex align-items-center">
                  <i class="iconfont icon-xuzhi1 pr-1"></i>
                  <span class="ws-n">重要提醒：</span>
                </div>
                <div class="d-flex justify-content-center w-100">
                  <div class="fs-9 pt-1" style="width: 95%;text-align: left;">
                    <ExpandText
                      :lineClamp="1"
                      v-if="lecturer.importantReminder"
                      :info="
                        teacherId ? lecturer.importantReminder || '无' : '无'
                      "
                    />
                    <span class="mx-2" v-else style="">无</span>
                    <span v-if="!IsPC && !lecturer.importantReminder"
                      >(此内容在pc端填写)</span
                    >
                  </div>
                </div>
              </div> -->
              <div class="d-flex mt-2" v-if="teacherId && [0, 1, 4, 5].includes(user.role)">
                <ScheduleData :user_id="teacherId" v-if="teacherId" />
              </div>
              <div v-if="!IsPC">
              <div class="trip text d-flex align-items-center">
                <div class="text-primary2 fs-9 d-flex align-items-center">
                  <i class="iconfont icon-xuzhi1 pr-1"></i>
                  <span class="ws-n">重要提醒：</span>
                </div>
                <div class="d-flex justify-content-between w-100">
                  <div class="fs-9 pt-1" style="width: 90%">
                    <ExpandText :lineClamp="1" v-if="lecturer.importantReminder" :info="teacherId ? lecturer.importantReminder || '无' : '无'
                      " />
                    <span class="mx-2" v-else>无</span>
                    <span v-if="!IsPC && !lecturer.importantReminder">(此内容在pc端填写)</span>
                  </div>
                </div>


              </div>
              <div class="w-100" v-if="user.teacher">
                <teacherData :user_id="user.teacher" />
              </div>
            </div>
            </div>

            <div class="w-100" v-else>
              <div class="d-flex justify-content-end mb-3 mt-2 mr-2">
                <div class="text-right">
                  <span class="pr-3 fs-9">数据月份:</span>
                  <el-date-picker :clearable="false" v-model="dates" type="monthrange" align="right" unlink-panels
                    range-separator="至" start-placeholder="开始月份" end-placeholder="结束月份" :picker-options="pickerOptions">
                  </el-date-picker>
                </div>
              </div>

              <avue-crud v-model="obj" :data="schedules" :option="scheduleOption" @row-del="remove" :page.sync="page"
                @search-change="search" @on-load="changPage" :table-loading="loading">
                <template slot="status" slot-scope="scope">
                  <div>{{ getStateName(scope.row.status) }}</div>
                </template>
                <template slot="teacher" slot-scope="scope">
                  <div>{{ _.get(scope.row, "teacher.name") }}</div>
                </template>
                <!-- <template slot="customer" slot-scope="scope">
              <div>{{ _.get(scope.row, "customer.name") }}</div>
            </template>-->
                <template slot="customer" slot-scope="scope">
                  <el-tooltip effect="dark" :content="_.get(scope.row, 'customer.name')" placement="top-start">
                    <div class="text-truncate cursor-pointer" style="max-width: 30vw">
                      {{ _.get(scope.row, "customer.name") }}
                    </div>
                  </el-tooltip>
                </template>

                <template slot="city" slot-scope="scope">
                  <el-tooltip effect="dark" :content="Array.isArray(scope.row.city)
                    ? _.get(scope.row, 'city').join('-')
                    : _.get(scope.row, 'city')
                    " placement="top-start">
                    <div>
                      <div class="text-truncate cursor-pointer" style="max-width: 20vw"
                        v-if="Array.isArray(scope.row.city)">
                        {{ _.get(scope.row, "city").join("-") }}
                      </div>
                      <div class="text-truncate cursor-pointer" style="max-width: 20vw" v-else>
                        {{ _.get(scope.row, "city") }}
                      </div>
                    </div>
                  </el-tooltip>
                </template>

                <template slot="post" slot-scope="scope">
                  <el-tooltip effect="dark" :content="_.get(scope.row, 'post')" placement="top-start">
                    <div class="text-truncate cursor-pointer" style="max-width: 18vw">
                      {{ _.get(scope.row, "post") }}
                    </div>
                  </el-tooltip>
                </template>

                <template #times="{ row }">
                  <el-tooltip effect="dark" :content="_.get(row, 'times', [])
                    .map((v) => $filters.formatDateT(v))
                    .join('，')
                    " placement="top-start">
                    <!-- <div v-for="(item, i) in _.get(row, 'times', [])" :key="i">
                  {{ $filters.formatDateT(item) }}
                </div>-->

                    <div class="cursor-pointer" :class="IsPC ? 'text-truncate1' : 'text-truncate'"
                      style="max-width: 20vw">
                      <!-- {{ scope.row.times && scope.row.times.join("<br>") | formatDateT
                  }}-->

                      <div class="times" v-for="(item, i) in _.get(row, 'times', []).slice(0, 4)" :key="i">
                        <span :key="i">{{ $filters.formatDateT(item) }}</span>
                        <!-- <br :key="`br-` + i" /> -->
                      </div>
                    </div>
                  </el-tooltip>
                </template>
                <template slot-scope="scope" slot="user">
                  <div>{{ _.get(scope.row, "user.realname") }}</div>
                </template>
                <template slot-scope="scope" slot="menu">
                  <el-button v-if="isEdit(scope.row)" icon="el-icon-edit" :size="scope.size" :type="scope.type"
                    @click="update(scope.row)">编辑</el-button>
                  <!-- <el-button
              v-if="isAdd == false && obj.status == 3"
              type="warning"
              @click="Reimbursement(obj)"
              >报销记录</el-button
              >-->
                </template>
              </avue-crud>
            </div>

            <!-- 
      <el-dialog
        title="报销记录"
        :visible.sync="dialogExpense"
        width="70%"
        :before-close="handleClose"
      >
        <avue-crud
          v-if="formRow"
          :data="formRow.reimbursement"
          :option="expenseOption"
          @row-save="expenseCreate"
          @row-del="expenseRemove"
          @row-update="expenseUpdate"
        >
        </avue-crud>
        </el-dialog>-->

            <el-dialog title="档期信息" :visible.sync="showPopup">
              <div class="cursor-pointer px-2" :class="'info_' + item._id" v-for="(item, index) in currentItems"
                :key="index">
                <div v-if="item.status < 10">
                  <div :class="`copy_text_${item._id}`">
                    <div class="my-1" v-if="item.status == 2 || item.status == 3">
                      {{ _.get(item, "teacher.name") }}老师:<br />
                      您好 现有订您的课程，具体信息如下：
                    </div>
                    <div v-else>讲师：{{ _.get(item, "teacher.name") }}</div>
                    <div class="my-1" v-if="item.status == 2 || item.status == 3">
                      1. 培训机构名称： {{ item.customer.name }}
                    </div>
                    <div class="my-1" v-if="item.status == 2 || item.status == 3">
                      2. 培训专题： {{ item.post || "" }}
                    </div>
                    <div class="my-1" v-if="item.status == 2 || item.status == 3">
                      3. 授课方式： {{ item.way == 0 ? "线上" : "线下" }}
                    </div>
                    <div class="my-1" v-if="item.status == 2 || item.status == 3">
                      4. 培训人数： {{ item.student_amount || 0 }} 人左右
                    </div>
                    <div class="my-1" v-if="item.status == 2 || item.status == 3">
                      5. 培训日期：
                      {{ filterTimes(item).join(",") || "" }} {{ getHour(item) }}
                    </div>
                    <div class="my-1" v-if="item.status == 2 || item.status == 3">
                      6. 学员单位： {{ item.terminal_name.trim() || "" }}
                    </div>
                    <div class="my-1" v-if="item.status == 2 || item.status == 3">
                      7. 学员岗位： {{ item.student_job.trim() || "" }}
                    </div>
                    <div class="my-1" v-if="item.status == 2 || item.status == 3">
                      8. 上课地点： {{ citys(item).join(",") || "" }}
                    </div>
                    <div class="my-1" v-if="item.status == 2 || item.status == 3">
                      9. 住宿地点： {{ item.stays_place || "" }}
                    </div>
                    <!-- <div
                      class="my-1"
                      v-if="item.status == 2 || item.status == 3"
                    >
                      9. 培训当地是否安排接送:
                      {{ item.is_pickup == 1 ? "是" : "否" }}
                    </div> -->
                    <div class="my-1" v-if="item.status == 2 || item.status == 3">
                      10. 助教姓名: {{ item.assistant }} 助教手机号码:{{
                        item.assistant_mobile || ""
                      }}
                    </div>
                    <div class="my-1" v-if="item.status == 2 || item.status == 3">
                      11. 项目经理姓名:{{ item.pm }} 项目经理手机号码:{{
                        item.pm_mobile || ""
                      }}
                    </div>
                    <!-- <div
                      class="my-1"
                      v-if="item.status == 2 || item.status == 3"
                    >
                      12. 需求:{{ item.demand || "" }}
                    </div> -->

                    <div class="my-1 d-flex" v-if="item.status == 2 || item.status == 3" style="white-space: pre-line">
                      12. 行程信息:
                      <span v-html="item.trip_info"></span>
                    </div>

                    <div class="my-1 d-flex" v-if="item.status == 2 || item.status == 3" style="white-space: pre-line">
                      13. 备注:
                      <span v-html="item.info"></span>
                    </div>

                    <div class="d-flex py-1" v-if="
                      (item.status == 2 || item.status == 3) &&
                      (user.role === 0 || user.role === 1 || user.role == 5)
                    ">
                      <span class="ws-n"> 14. 合同图片：</span>
                      <div>
                        <el-image class="mx-1" v-for="img in item.contract" :key="img"
                          style="width: 100px; height: 100px" :src="img" fit="cover"
                          :preview-src-list="item.contract"></el-image>
                      </div>
                    </div>


                    <div class="my-1 d-flex" v-if="item.status == 2 || item.status == 3" style="white-space: pre-line">
                      15. 机构联系人:
                      {{ item.contact }}
                    </div>

                    <div class="my-1 d-flex" v-if="item.status == 2 || item.status == 3" style="white-space: pre-line">
                      16. 创建人:
                      {{ _.get(item, "user.realname") }}
                    </div>
                    <div class="my-1 d-flex" v-if="item.status == 2 || item.status == 3">
                      17. 讲师课酬:


                      <span class="mx-1" v-if="(item.status == 2 || item.status == 3)">{{
                        item.order_amount
                      }}</span>
                    </div>

                    <div v-if="(item.status == 2 || item.status == 3)">
                      <span class="ws-n">18. 创建时间：</span>{{ $filters.formatDateY(item.createdAt) }}
                    </div>





                    <div class="d-flex" v-if="item.status !== 2 && item.status !== 3">
                      <span class="ws-n">授课方式：</span>
                      {{ item.way == 0 ? "线上" : "线下" }}
                    </div>
                    <div class="d-flex" v-if="item.status !== 2 && item.status !== 3">
                      <el-tooltip effect="dark" :content="_.get(item, 'times', [])
                        .map((v) => $filters.formatDateT(v))
                        .join('，')
                        " placement="top-start">
                        <div class="cursor-pointer text-truncate">
                          <span class="ws-n">上课日期：</span>
                          {{ item.times && item.times.join(",") | formatDateT }}
                        </div>
                      </el-tooltip>
                    </div>
                    <div class="d-flex" v-if="item.status !== 2 && item.status !== 3">
                      <span class="ws-n">学员岗位：</span>
                      {{ item.student_job }}
                    </div>
                    <!-- <div
                      class="d-flex"
                      v-if="item.status == 2 || item.status == 3"
                    >
                      <span class="ws-n">学员人数：</span>
                      {{ item.student_amount }}
                    </div> -->
                    <div class="d-flex" v-if="item.post && item.status !== 2 && item.status !== 3">
                      <span class="ws-n">课程：</span>
                      {{ item.post }}
                    </div>
                    <div class="d-flex" v-if="
                      Array.isArray(item.city) &&
                      item.status !== 2 &&
                      item.status !== 3
                    ">
                      <span class="ws-n">地址：</span>
                      {{ item.city && item.city.join(",") }}
                      {{ item.address }}
                    </div>
                    <div class="d-flex" v-else-if="
                      !Array.isArray(item.city) &&
                      item.status !== 2 &&
                      item.status !== 3
                    ">
                      <span class="ws-n">地址：</span>
                      {{ item.city }}
                      {{ item.address }}
                    </div>
                    <div v-if="item.status !== 2 && item.status !== 3">
                      <span class="ws-n">客户：</span>
                      {{ _.get(item, "customer.name") }}
                    </div>
                    <div v-if="
                      item.terminal_name &&
                      item.status !== 2 &&
                      item.status !== 3
                    ">
                      <span class="ws-n">终端：</span>
                      {{ _.get(item, "terminal_name") }}
                    </div>


                    <!-- <div
                      class="d-flex"
                      v-if="item.status == 2 || item.status == 3"
                    >
                      <div v-if="item.trip_info && item.way == 1">
                        <span class="ws-n">行程信息：</span>
                        <span
                          v-html="item.trip_info"
                          style="white-space: pre-line"
                        ></span>
                      </div>
                    </div> -->
                    <div class="d-flex" v-if="item.info && item.status !== 2 && item.status !== 3">
                      <span class="ws-n">备注：</span>
                      <span v-html="item.info" style="white-space: pre-line"></span>
                    </div>
                    <div v-if="item.status !== 2 && item.status !== 3">
                      创建日期：{{ $filters.formatDate(item.updatedAt) }}
                    </div>
                    <div v-if="item.status !== 2 && item.status !== 3">
                      创建人：{{ _.get(item, "user.realname") }}
                    </div>
                    <!-- {{ item.status }} -->

                    <!-- {{ colour(item) === 'success' }} -->

                    <div v-if="(item.status == 1 || item.status == 2) && user.role == 2">
                      <span class="ws-n">讲师备注：</span>
                      <el-input class="my-2" type="textarea" v-model="remark" placeholder="请输入备注" />
                    </div>
                    <!-- <div 
                      class="d-flex py-1"
                      v-if="
                        (item.status == 2 || item.status == 3) &&
                        (user.role === 0 || user.role === 1 || user.role == 5)
                      "
                    >
                      <span class="ws-n">合同图片：</span>
                      <div>
                        <el-image
                          class="mx-1"
                          v-for="img in item.contract"
                          :key="img"
                          style="width: 100px; height: 100px"
                          :src="img"
                          fit="cover"
                          :preview-src-list="item.contract"
                        ></el-image>
                      </div>
                    </div> -->
                    <!-- <div>
                 16讲师课酬：<span v-if="item.way == 1">{{
                    _.get(item, "teacher.price") * item.fate
                  }}</span>
                  <span v-else>{{
                    _.get(item, "teacher.onlinePrice") * item.hour
                  }}</span>
                </div> -->
                    <div v-if="
                      item.order_amount &&
                      item.status !== 2 &&
                      item.status !== 3
                    ">
                      <span class="ws-n">订单总额：</span>{{ _.get(item, "order_amount") }}
                    </div>

                    <div v-if="
                      item.isSettle && item.status !== 2 && item.status !== 3
                    ">
                      <span class="ws-n">课酬结清日期：</span>{{ $filters.formatDateY(item.settle_date) }}
                    </div>



                    <div class="text-center py-2">
                      <el-button class="mr-2" @click="showPopup = false">取 消</el-button>
                      <el-button class="mr-2" type="success" @click="submitRemark(item)"
                        v-if="user.role == 2">提交修改</el-button>
                      <el-button v-if="isEdit(item)" @click="scheduleClick('', item)" type="primary">编辑</el-button>
                      <el-button v-if="isShow(item)" type="success" @click="invoicing(item)">申请开票</el-button>

                      <el-button type="success" v-if="item.status == 2" class="ml-3"
                        @click="getText(item)">一键生成确认函</el-button>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div v-if="item.status !== 13">
                    讲师：{{ _.get(item, "teacher.name") }}
                  </div>
                  <div class="d-flex">
                    <span class="ws-n">时间：</span>
                    {{ item.times && item.times.join(",") | formatDateT }}
                  </div>
                  <div class="d-flex" v-if="item.status !== 13">
                    <span class="ws-n">地址：</span>
                    {{ item.city && item.city.join(",") }}
                  </div>
                  <div class="d-flex" v-if="item.matter">
                    <span class="ws-n">事项内容：</span>
                    {{ item.matter }}
                  </div>
                  <div>创建日期：{{ $filters.formatDate(item.createdAt) }}</div>
                  <div>创建人：{{ _.get(item, "user.realname") }}</div>

                  <div v-if="user.role == 2">
                    <span class="ws-n">讲师备注：</span>
                    <el-input class="my-2" type="textarea" v-model="remark" placeholder="请输入备注" />
                  </div>
                  <div class="text-center py-2">
                    <el-button class="mr-2" @click="showPopup = false">取 消</el-button>
                    <el-button class="mr-2" type="success" @click="submitRemark(item)"
                      v-if="user.role == 2">提交修改</el-button>
                    <el-button v-if="isEdit(item)" @click="scheduleClick('', item)" type="primary">编辑</el-button>
                  </div>
                </div>
              </div>
            </el-dialog>

            <el-dialog title="提醒详情" :visible.sync="dialogRemind" width="40%" :before-close="handleClose">
              <div class="d-flex justify-content-center">
                {{ lecturer.remindDetail || "暂无提醒详情" }}
              </div>
              <div class="text-center">
                <span v-if="!IsPC && !lecturer.importantReminder" class="text-truncate">(此内容在pc端填写)</span>
              </div>
            </el-dialog>

            <el-dialog title="导出" :visible.sync="dialogExport" width="50%" :before-close="handleClose">
              <avue-form v-model="exportForm" :option="exportOption" @submit="exportSubmit">
                <template #tree2="{ disabled }">
                  <div>
                    <MultipleSelect :disabled="disabled" filterable :selectOptions="tree2Options"
                      v-model="exportForm.tree2" />
                  </div>
                </template>

                <template #field="{ disabled }">
                  <div>
                    <MultipleSelect :disabled="disabled" filterable :selectOptions="treeOptions"
                      v-model="exportForm.field" />
                  </div>
                </template>
              </avue-form>
            </el-dialog>
          </div>
        
        </div>
      </el-col>
      <el-col :span="2" :xl="2" :lg="2" :md="4"></el-col>
      <el-dialog class="dialog" :close-on-click-modal="false" :title="optTitle" :visible.sync="dialogVisible"
            :before-close="beforeClose" ref="dialog" width="76%">
            <div class="pb-3 pl-2" v-if="eveningClass && obj.teacher">
              <i class="iconfont icon-xuzhi1 pr-1 text-primary2"></i>
              <span class="font-weight-bold fs-9">排课提醒：</span>
              <span style="color: red">{{ eveningClass }}</span>
            </div>
            <avue-form v-if="dialogVisible" ref="form" :option="scheduleOption" v-model="obj" @submit="submit"
              :upload-preview="preview" :upload-after="uploadDone" :upload-exceed="uploadExceed"
              :upload-delete="uploadDelete">
              <template slot-scope="{}" slot="text">
                <div>
                  <el-button size="small" type="primary" @click="send(obj)">发送</el-button>
                  <span class="pl-2" v-if="obj.isSendSMS">(已发送)</span>
                  <span class="pl-2" v-else>(未发送)</span>
                  <div>{{ "用于发送短信通知该老师课酬结清日期" }}</div>
                  <div></div>
                </div>
              </template>
              <template #menuForm>
                <el-button v-if="!isAdd" type="danger" @click="removeCalendar(obj._id)">删除</el-button>

                <!-- <el-button
              v-if="isAdd"
              type="danger"
              @click="removeCalendar(obj._id)"
              >清空</el-button
            >-->
                <!-- <el-button
              v-if="isAdd == false && obj.status == 3"
              type="warning"
              @click="Reimbursement(obj)"
              >报销记录</el-button
            >-->
              </template>
            </avue-form>
          </el-dialog>

          <el-dialog :close-on-click-modal="false" title="申请开票" :visible.sync="dialogInvoicing"
            :before-close="beforeClose" width="76%">
            <avue-form v-if="dialogInvoicing" ref="form" :option="invoicingOption" v-model="obj"
              @submit="invoicingSubmit" :upload-preview="preview">
              <!-- <template #menuForm>
            <el-button type="danger" @click="invoicingSubmit(obj._id)"
              >保存</el-button
            >
          </template> -->
            </avue-form>
          </el-dialog>
    </el-row>
  </div>
</template>

<script>
import * as dayjs from "dayjs";
import { isMobile, validMobile } from "@/filters";

import * as utc from "dayjs/plugin/utc";
import * as timezone from "dayjs/plugin/timezone";
import teacherData from "./TeacherData.vue";
import ScheduleData from "./ScheduleData.vue";
import ExpandText from "../../components/ExpandText.vue";
import calendar from "./../../plugins/lunarDay";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Shanghai");
const findLabels = (obj) => {
  let labels = [];
  if (obj.data && obj.data.label && obj.level !== 0) {
    labels.push(obj.data.label);
  }
  if (obj.parent) {
    labels = labels.concat(findLabels(obj.parent));
  }
  return labels;
};
const importField = [
  {
    label: "讲师",
    value: "讲师",
  },
  {
    label: "讲师课酬(线下)",
    value: "讲师课酬(线下)",
  },
  {
    label: "讲师课酬(线上)",
    value: "讲师课酬(线上)",
  },
  {
    label: "档期状态",
    value: "档期状态",
  },
  {
    label: "授课方式",
    value: "授课方式",
  },
  {
    label: "上课日期",
    value: "上课日期",
  },
  {
    label: "总时长(小时)",
    value: "总时长(小时)",
  },
  {
    label: "总时长(天)",
    value: "总时长(天)",
  },
  {
    label: "客户名称",
    value: "客户名称",
  },
  {
    label: "课程名称",
    value: "课程名称",
  },
  {
    label: "授课类别",
    value: "授课类别",
  },
  {
    label: "银行类别",
    value: "银行类别",
  },
  {
    label: "项目经理",
    value: "项目经理",
  },
  {
    label: "授课省份",
    value: "授课省份",
  },
  {
    label: "授课城市",
    value: "授课城市",
  },
  {
    label: "授课地区",
    value: "授课地区",
  },
  // {
  //   label: "授课详细地址",
  //   value: "授课详细地址",
  // },
  {
    label: "终端名称",
    value: "终端名称",
  },
  {
    label: "学员岗位",
    value: "学员岗位",
  },
  {
    label: "合同签订时间",
    value: "合同签订时间",
  },
  {
    label: "行程信息",
    value: "行程信息",
  },
  {
    label: "需求",
    value: "需求",
  },
  {
    label: "确认函",
    value: "确认函",
  },
  // {
  //   label: "课件是否发送",
  //   value: "课件是否发送",
  // },
  // {
  //   label: "物料是否发送",
  //   value: "物料是否发送",
  // },
  {
    label: "开票公司",
    value: "开票公司",
  },
  {
    label: "回款日期",
    value: "回款日期",
  },
  {
    label: "备注",
    value: "备注",
  },
  {
    label: "是否报销",
    value: "是否报销",
  },
  {
    label: "报销总额",
    value: "报销总额",
  },
  {
    label: "订单总额",
    value: "订单总额",
  },
  {
    label: "满意度",
    value: "满意度",
  },
  {
    label: "创建人",
    value: "创建人",
  },
];
export default {
  // name: "cache",
  data() {
    return {
      remark: "",
      to_status: null,
      schedule_loading: true,
      isTextOverflow: false,
      agentId: null,
      totalYear: "",
      ellipsis: true,
      select_times: [
        {
          label: "上个月",
          value: "prev",
        },
        {
          label: "今天",
          value: "now",
        },
        {
          label: "下个月",
          value: "next",
        },
      ],
      schedule_list: [
        {
          key: "app_course",
          name: "预讲课程",
          count: 2,
          unit: "天",
        },
        {
          key: "wait_course",
          name: "待讲课程",
          count: 2,
          unit: "天",
        },

        {
          key: "done_course",
          name: "今年完课",
          count: 2,
          unit: "天",
        },
      ],
      users: [],
      yearDate: new Date(),
      props: { multiple: true, emitPath: false },
      members: [],
      groups: [],
      days: null,
      hours: null,
      more: null,
      eveningClass: null,
      timer: null,
      showDuration: false,
      dialogImg: false,
      isAmount: false,
      invoice_amount: 0,
      general_ticket: 0,
      special_ticket: 0,
      electronic_ticket: 0,
      electronic_special_ticket: 0,
      invoiceType: [],
      teacherList: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      times: null,
      value4: "",
      showPopup: false,
      currentItems: [],
      currentDate: dayjs().startOf("day").toDate(),
      cities: [],
      customerOption: [],
      isCalendar: true,
      page: {
        total: 0,
        pageSize: 20,
      },
      searchName: null,
      searchCustomer: null,
      searchWay: null,
      searchCategory: null,
      searchCity: null,
      query: {
        limit: 10,
        sort: { times: 1 },
        populate: [
          {
            path: "customer",
            select: "name",
          },
          "user",
          {
            path: "teacher",
            select: "name",
          },
          "billing_company",
        ],
      },
      permission: 1,
      exportForm: {
        daterange: [],
        tree2: [],
        field: [],
      },
      lecturer: {},
      dialogExport: false,
      dialogExpense: false,
      formRow: {},
      scheduleState: 0,
      state: [
        {
          label: "全部",
          value: 0,
        },
        {
          label: "预定",
          value: 1,
        },
        {
          label: "确定",
          value: 2,
        },
        {
          label: "已实施",
          value: 3,
        },
        {
          label: "讲师私事",
          value: 12,
        },
        {
          label: "节假日",
          value: 13,
        },
      ],
      teacherOption: [],
      brokers: [],
      teacherId: " ",
      userId: null,
      payBack_type: null,
      scheduleId: null,
      customerId: null,
      cooperateDate: null,
      teacher: {},
      showState: false,
      obj: {
        customer: "",
        way: 1,
        status: 1,
        confirmation: 2,
        times: [],
      },
      schedules: [],
      isAdd: false,
      isDay: false,
      form: {
        id: null,
        EventTitle: null,
        EventTime: null,
      },
      calendarEvents: [],
      dialogRemind: false,
      dialogVisible: false,
      dialogInvoicing: false,
      optTitle: "添加档期",
      loading: false,
      value: new Date(),
      dates: [
        dayjs().subtract(1, "year").startOf("month").toString(),
        dayjs().toString(),
      ],
      treeOptions: importField,
      tree2Options: [
        {
          label: "预定",
          value: 1,
        },
        {
          label: "确定",
          value: 2,
        },
        {
          label: "已实施",
          value: 3,
        },
      ],
      exportOption: {
        translate: false,
        column: [
          {
            label: "档期状态",
            prop: "tree2",
            type: "select",
            multiple: true,
            filterable: true,
            all: true,
            slot: true,
            rules: [
              {
                required: true,
                message: "请选择导出的档期状态",
                trigger: "blur",
              },
            ],
            dicData: [],
          },
          // {
          //   label: "物料发送状态",
          //   prop: "isMateriel_send",
          //   type: "tree",
          //   rules: [
          //     {
          //       required: true,
          //       message: "请选择导出的物料发送状态",
          //       trigger: "blur",
          //     },
          //   ],
          //   dicData: [
          //     {
          //       label: "全部",
          //       value: 0,
          //     },
          //     {
          //       label: "已发送",
          //       value: 1,
          //     },
          //   ],
          // },
          // {
          //   label: "课件发送状态",
          //   prop: "isCourseware_send",
          //   type: "tree",
          //   rules: [
          //     {
          //       required: true,
          //       message: "请选择导出的课件发送状态",
          //       trigger: "blur",
          //     },
          //   ],
          //   dicData: [
          //     {
          //       label: "全部",
          //       value: 0,
          //     },
          //     {
          //       label: "已发送",
          //       value: 1,
          //     },
          //   ],
          // },
          {
            label: "导出内容",
            prop: "field",
            type: "tree",
            slot: true,
            type: "select",
            multiple: true,
            all: true,
            filterable: true,
            rules: [
              {
                required: true,
                message: "请选择导出的内容",
                trigger: "blur",
              },
            ],
            slot: true,
            dicData: importField,
          },
          {
            label: "日期范围",
            prop: "daterange",
            type: "daterange",
            startPlaceholder: "选择导出的开始时间",
            endPlaceholder: "选择导出的结束时间",
            span: 24,
            valueFormat: "timestamp",
            rules: [
              {
                required: true,
                message: "请选择导出的日期范围",
                trigger: "blur",
              },
            ],
          },
        ],
      },
      expenseOption: {
        translate: false,
        mockBtn: true,
        align: "center",
        column: [
          {
            label: "报销凭证",
            prop: "images",
            type: "upload",
            dataType: "array",
            listType: "picture-card",
            action: "upload",
            span: 24,
          },
          {
            label: "备注",
            prop: "info",
            type: "textarea",
            span: 24,
            minRows: 2,
            placeholder: "备注",
          },
        ],
      },
      contacts: [],
      invoices: [],
      billingCompanies: [],
      status: [],
      isShowUi: false,
      date: null,
      pickerOptions: {
        shortcuts: [
          {
            text: "本月",
            onClick(picker) {
              picker.$emit("pick", [new Date(), new Date()]);
            },
          },
          {
            text: "今年至今",
            onClick(picker) {
              const end = new Date();
              const start = new Date(new Date().getFullYear(), 0);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近六个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth() - 6);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  watch: {
    members(value) {
      this.fetch();
    },
    agentId() {
      this.fetch();
    },
    dates() {
      this.fetch();
    },
    value() {
      this.changeDate();
      // this.fetchReport();
    },
    isCalendar() {
      this.schedules = [];
      if (this.isCalendar) {
        // this.value = new Date()
        this.fetch();
      }
    },
    teacherId() {
      this.fetch();
      if (this.teacherId) {
        this.fetchLecturer(this.teacherId);
      }
    },
    scheduleState() {
      this.fetch();
    },
    permission() {
      this.fetch();
    },
    "obj.duration": {
      handler(value) {
        if (this.obj.duration) {
          const way = this.obj.way;
          if (way == 1) {
            this.obj.fate = this.obj.duration.reduce((total, cur) => {
              return total += cur.day ?? 0;
            }, 0)
          } else {
            this.obj.hour = this.obj.duration.reduce((total, cur) => {
              return total += cur.hour ?? 0;
            }, 0)
          }

        }
      },
      deep: true,
    },
    "obj.collection_voucher": {
      handler(value) {
        if (this.isDay) {
          if (value && value.length > 0 && value.length < 2) {
            // console.log(this.obj.payBack);
            this.obj.payBack = [
              {
                money: this.obj.order_amount,
                date: new Date(),
              },
            ];
          }
        }
      },
      immediate: true,
    },

    "obj.times": {
      immediate: false,
      handler(value) {
        // this.isDay = true;
        if (value && value.length > 0 && this.isDay) {
          this.fetchFate(value);
        }
      },
    },
    "obj.teacher": {
      handler(id) {
        if (this.obj.times?.length > 0 && this.isDay && this.isAdd) {
          this.repeatTip(this.obj.times);
        }
        if ((id && this.isDay) || this.isAdd) {
          this.fetchPay(id);
        }
        if (id || this.isAdd) {
          this.fetchQuotation(id);
        }
        if (id) {
          this.remind(id);
        } else {
          this.obj.price = "";
        }
      },
      immediate: true,
    },
    "obj.way": {
      handler(value) {
        if ((this.obj.teacher && this.isDay) || this.isAdd) {
          this.fetchWay(value);
        }
      },
    },
    "obj.customer": {
      async handler(id) {
        if (id) {
          // this.fetchCustomer(id)
          this.fetchContact(id);
          this.fetchInvoice(id);
        }
      },
      immediate: true,
    },
    "obj.billing_company": {
      async handler(id) {
        if (id) {
          await this.fetchInvoiceType(id);
          this.fetchAmount(this.obj.billing_type);
        } else {
          this.obj.billing_type = "";
          this.obj.invoice_amount = 0;
        }
      },
    },

    "obj.billing_type": {
      async handler(v) {
        if (v && this.isAmount) {
          this.fetchAmount(v);
        }
      },
    },
  },
  components: {
    teacherData,
    ScheduleData,
    ExpandText,
  },

  computed: {
    now_date() {
      return `${dayjs(this.value).year()} 年 ${dayjs(this.value).month() + 1
        } 月 `;
    },
    teacherUser() {
      return this.user.role == 2 ? this.user.teacher : this.teacherId;
    },
    // 是否节假日
    isFestival() {
      // const date=slotData.getDate();
      return (slotData) => {
        const day = dayjs(slotData).format("YYYY-MM-DD");
        let solarDayArr = day.split("-");
        let lunarDay = calendar.solar2lunar(
          solarDayArr[0],
          solarDayArr[1],
          solarDayArr[2]
        );

        // 公历节日\农历节日\农历节气
        let festAndTerm = [];
        festAndTerm.push(
          lunarDay.festival == null ? "" : " " + lunarDay.festival
        );
        festAndTerm.push(
          lunarDay.lunarFestival == null ? "" : "" + lunarDay.lunarFestival
        );
        festAndTerm.push(lunarDay.Term == null ? "" : "" + lunarDay.Term);
        festAndTerm = festAndTerm.join("");

        return festAndTerm != "";
      };
    },

    // 公历转农历
    solarToLunar() {
      return (slotData) => {
        const day = dayjs(slotData).format("YYYY-MM-DD");
        let solarDayArr = day.split("-");
        let lunarDay = calendar.solar2lunar(
          solarDayArr[0],
          solarDayArr[1],
          solarDayArr[2]
        );

        // 农历日期
        let lunarMD = lunarDay.IMonthCn + lunarDay.IDayCn;

        // 公历节日\农历节日\农历节气
        let festAndTerm = "";
        festAndTerm +=
          lunarDay.festival == null ? "" : lunarDay.festival + "<br />";
        festAndTerm +=
          lunarDay.lunarFestival == null
            ? ""
            : lunarDay.lunarFestival + "<br />";
        festAndTerm += lunarDay.Term == null ? "" : lunarDay.Term + "<br />";
        return festAndTerm == "" ? lunarMD : festAndTerm;
      };
    },
    showRadio() {
      if (this.IsPC) {
        return true;
      }
      const roles = [0, 1, 5, 4];
      return roles.includes(this.user.role) && !this.IsPC ? false : true;
    },

    cur_select_times() {
      const roles = [0, 1, 5, 4];
      return !this.IsPC && roles.includes(this.user.role)
        ? this.select_times.filter((v) => v.value !== "now")
        : this.select_times;
    },

    // value(){
    //   let time
    //   if(this.isCalendar){
    //     time = new Date()
    //   }else {
    //     time = [
    //     dayjs()
    //       .subtract(1, "year")
    //       .startOf("month")
    //       .toString(),
    //     dayjs().toString(),
    //   ]
    //   }
    //   return time
    // },
    save_teacher() {
      return localStorage.getItem("teacherId");
    },
    indexed() {
      let times = this.schedules.reduce((acc, v) => {
        v.times.map((d) => {
          const key = dayjs(d).tz("Asia/Shanghai").format("YYYYMMDD");
          if (!Array.isArray(acc[key])) {
            acc[key] = [];
          }
          if (!acc[key].find((vv) => vv._id === v._id)) {
            acc[key].push(v);
          }
        });
        return acc;
      }, {});

      // console.log(times)

      return times;
    },
    isContractUpload() {
      let state = true;
      if (
        (this.obj.contract && this.obj.contract.length > 0) ||
        (this.obj.file && this.obj.file.length > 0)
      ) {
        state = false;
      }
      return state;
    },
    isDemandUpload() {
      let state = true;
      if (this.obj.demand) {
        state = false;
      }
      return state;
    },

    isDemand() {
      let state = true;
      if (this.obj.demandImg && this.obj.demandImg.length > 0) {
        state = false;
      }
      return state;
    },
    // 联系人是否必填判断条件
    isContactRequired() {
      let state = false;
      if (
        this.contacts[0] &&
        this.contacts[0].name &&
        this.contacts[0].name.length > 1
      ) {
        state = true;
      }
      return state;
    },
    //确认状态下必填项
    isConfirm() {
      let state = false;
      if (this.obj.status > 1) {
        state = true;
      }
      return state;
    },
    isRequired() {
      let state = true;
      if (this.obj.status == 1) {
        state = false;
      }
      return state;
    },

    invoicingOption() {
      return {
        column: [
          {
            label: "开票公司",
            prop: "billing_company",
            props: {
              label: "company",
              value: "_id",
            },
            rules: [
              {
                required: true,
                message: "请选择开票公司",
                trigger: "blur",
              },
            ],
            type: "select",
            dicData: this.billingCompanies,
            dicQuery: {
              query: {
                limit: 0,
              },
            },
            span: 12,
            display: this.InvoiceField && this.isBilling_company,
            showColumn: false,
            hide: true,
          },
          {
            label: "客户开票公司",
            filterable: true,
            prop: "customer_billing_company",
            type: "select",
            span: 12,
            props: {
              label: "company",
              value: "company",
              // res: 'contacts',
              // children: 'contacts'
            },
            dicData: this.invoices,
            display: this.InvoiceField && this.isBilling_company,
            hide: true,
            showColumn: false,
          },
          {
            label: "发票类型",
            prop: "billing_type",
            type: "radio",
            dicData: [
              {
                label: `普票(税率:${this.general_ticket}%)`,
                value: "普票",
              },
              {
                label: `专票(税率:${this.special_ticket}%)`,
                value: "专票",
              },
              {
                label: `电子普票(税率:${this.electronic_ticket}%)`,
                value: "电子普票",
              },
              {
                label: `电子专票(税率:${this.electronic_special_ticket}%)`,
                value: "电子专票",
              },
            ],
            rules: [
              {
                required: true,
                message: "请选择发票类型",
                trigger: "blur",
              },
            ],
            span: 12,
            display: this.InvoiceField && this.isBilling_company,
            showColumn: false,
            hide: true,
          },
          {
            label: "发票金额",
            prop: "invoice_amount",
            type: "number",
            span: 12,
            rules: [
              {
                required: true,
                message: "请填写发票金额",
                trigger: "blur",
              },
            ],
            display: this.InvoiceField && this.isBilling_company,
            showColumn: false,
            hide: true,
          },
          {
            label: "邮寄联系人",
            prop: "mailing_contact",
            span: 12,
            rules: [
              {
                required: true,
                message: "请填写邮寄联系人",
                trigger: "blur",
              },
            ],
            display: this.InvoiceField && this.isBilling_company,
            showColumn: false,
            hide: true,
          },
          {
            label: "邮寄联系电话",
            prop: "mailing_mobile",
            span: 12,
            rules: [
              {
                required: true,
                message: "请填写邮寄联系电话",
                trigger: "blur",
              },
            ],
            display: this.InvoiceField && this.isBilling_company,
            showColumn: false,
            hide: true,
          },
          {
            label: "邮寄地址",
            prop: "mailing_address",
            type: "textarea",
            rules: [
              {
                required: true,
                message: "请填写邮寄地址",
                trigger: "blur",
              },
            ],
            span: 12,
            minRows: 2,
            display: this.InvoiceField && this.isBilling_company,
            hide: true,
            showColumn: false,
          },
        ],
      };
    },

    scheduleOption() {
      const state = this.obj.status;
      const way = this.obj.way;
      let dates = this.obj.times;
      var hour = (rule, value, callback) => {
        if (value !== undefined) {
          if (way == 0) {
            if (value > 0) {
              callback();
            } else {
              callback(new Error("请输入正确的总时长(小时)"));
            }
          } else if (way == 1) {
            if (value > 0) {
              callback();
            } else {
              callback(new Error("请输入正确的总时长(天)"));
            }
          }
        } else {
          way == 0
            ? callback(new Error("请输入总时长(小时)"))
            : callback(new Error("请输入总时长(天)"));
        }
      };

      const waves = this.obj.course_ware || [];

      //  var day = (rule, value, callback) => {
      //   if (value !== undefined) {
      //     if (way == 1 && value > 0 && this.days == value) {
      //       callback();
      //     } else {
      //       callback(new Error("请输入正确的总时长(小时)"));
      //     }
      //   } else {
      //     callback(new Error("请输入总时长(小时)"));
      //   }
      // };
      return {
        size: "mini",
        align: "center",
        border: true,
        index: true,
        indexLabel: "序号",
        searchMenuSpan: 4,
        header: false,
        dialogClickModal: false,
        emptyBtn: false,
        border: true,
        menuWidth: 160,
        translate: false,
        editBtn: false,
        column: [
          {
            label: "档期状态",
            prop: "status",
            type: "radio",
            border: true,
            span: 24,
            rules: [
              {
                required: true,
                message: "请选择档期状态",
                trigger: "blur",
              },
            ],
            dicData: this.status,
            slot: true,
          },
          {
            label: state === 11 ? "报备讲师" : "讲师",
            prop: "teacher",
            filterable: true,
            props: {
              label: "name",
              value: "_id",
            },
            span: 8,
            type: "select",
            rules: [
              {
                required: true,
                message: "请选择讲师",
                trigger: "blur",
              },
            ],
            display: state >= 1 && this.isVacation,
            slot: true,
            // dicData: this.teacherOption,
            dicUrl: `teachers/option`,
            dicQuery: {
              query: {
                limit: 0,
              },
            },
          },

          {
            label: state == 14 ? "合作机构" : "客户名称",
            filterable: true,
            prop: "customer",
            slot: true,
            props: {
              label: "name",
              value: "_id",
            },
            span: 8,
            searchFilterable: true,
            rules: [
              {
                required: true,
                message: "请选择客户名称",
                trigger: "blur",
              },
            ],
            width: "140px",
            search: true,
            // remote: true,
            type: "select",
            dicUrl: "customers/remain_options",
            // dicFlag: true,
            // dicData: this.customerOption,
            // dicQuery: {
            //   query: {
            //     limit: 0,
            //   },
            // },
            // hide:true,
            display: state < 12 || state == 14,
          },

          {
            label: "联系人",
            filterable: true,
            prop: "contact",
            type: "select",
            span: 8,
            rules: [
              {
                required: true,
                message: "请选择客户联系人",
                trigger: "blur",
              },
            ],
            props: {
              label: "name",
              value: "name",
              // res: 'contacts',
              // children: 'contacts'
            },
            dicData: this.contacts,
            showColumn: false,
            hide: true,
            // rules: [
            //   {
            //     required: this.isContactRequired,
            //     message: "请选择授课方式",
            //     trigger: "blur",
            //   },
            // ],
            display: state < 10,
          },
          {
            label: "授课方式",
            prop: "way",
            search: true,
            type: "radio",
            span: 8,
            rules: [
              {
                required: true,
                message: "请选择授课方式",
                trigger: "blur",
              },
            ],
            display: state < 10,
            dicData: [
              {
                label: "线上",
                value: 0,
              },
              {
                label: "线下",
                value: 1,
              },
            ],
          },

          {
            label: state < 12 ? "上课日期" : "时间",
            prop: "times",
            type: "dates",
            format: "yyyy-MM-dd",
            formatter: (row, value) => value && value.join("-"),
            rules: [
              {
                required: true,
                message: "请选择上课时间",
                trigger: "blur",
              },
            ],
            display: state !== 11 && state !== 14,
            slot: true,
            width: "106px",
            span: 16,
            change: (e) => {
              setTimeout(() => {
                this.isDay = true;
              }, 1500);
              // console.log(this.isDay, "day");
              const times = e.value;
              if (this.isDay && this.isAdd) {
                this.repeatTip(times);
              }
              const duration = _.mapValues(
                _.groupBy(times, (v) => dayjs(v).format("YYYY-MM")),
                "length"
              );

              const length = Object.keys(duration).length;
              if (this.isDay && length < 1) {
                // this.showDuration = false;
                this.obj.duration = [];
              }
              if (state < 10) {
                // this.showDuration = true;
                if (this.isDay) {
                  if (this.obj.way == 1) {
                    this.obj.duration = Object.entries(duration).map(
                      ([month, day]) =>
                        this.obj.way == 1 ? { month, day } : { month }
                    );
                  } else if (this.obj.way == 0) {
                    this.obj.duration = Object.entries(duration).map(
                      ([month, hour]) =>
                        this.obj.way == 0 ? { month, hour } : { month }
                    );
                  }
                }
              }

              // this.obj.duration = [];
              // for (let key in duration) {
              //   this.obj.duration.push({
              //     month: key,
              //     day: duration[key],
              //   });
              // }

              // console.log(this.obj.duration);
            },
            blur: (times) => { },
            pickerOptions: {
              shortcuts: [
                {
                  text: "填充日期",
                  onClick: (picker) => {
                    const times = [...this.obj.times];
                    times.sort((a, b) => a - b);
                    const from = times[0];
                    const to = times[times.length - 1];
                    if (!from || !to) {
                      this.$message.warning("请选择起始与结束日期");
                      return;
                    }
                    const diffDays = dayjs(to).diff(from, "d");
                    this.obj.times = Array(diffDays + 1)
                      .fill(1)
                      .map((v, i) => {
                        return dayjs(from).add(i, "d").toDate();
                      });
                  },
                },
              ],
            },
          },

          {
            label: "填写时长",
            prop: "duration",
            type: "dynamic",
            display: state < 10,
            tip: "根据上课日期选择的月份，分别填入月份时长",
            span: 12,
            hide: true,
            formslot: true,
            rules: [
              {
                required: true,
                message: "请填写时长",
                trigger: "blur",
              },
            ],
            // display: this.ImplementedField && this.isTeacher,
            children: {
              column: [
                {
                  label: "月份",
                  prop: "month",
                  type: "month",
                  width: "130px",
                },
                {
                  label: "线下(天)",
                  prop: "day",
                  type: "number",
                  change: (e) => {
                    let day = null;
                    this.obj.duration.forEach((v) => {
                      return (day += v.day);
                    });
                    this.obj.fate = day;
                    this.days = day;
                  },
                  disabled: way == 0,
                  rules: [
                    {
                      required: way == 1,
                      message: "请输入时长(天)",
                      trigger: "blur",
                    },
                  ],
                },
                {
                  label: "线上(小时)",
                  prop: "hour",
                  type: "number",
                  change: (e) => {
                    let hour = null;
                    this.obj.duration.forEach((v) => {
                      return (hour += v.hour);
                    });
                    this.obj.hour = hour;
                    this.hours = hour;
                  },
                  disabled: way == 1,
                  rules: [
                    {
                      required: way == 0,
                      message: "请输入时长(小时)",
                      trigger: "blur",
                    },
                  ],
                },
              ],
            },
          },

          {
            label: "选择晚上有课的日期",
            prop: "nights",
            type: "select",
            multiple: true,
            props: {
              label: "label",
              value: "value",
            },
            dicData: this.times,
            format: "yyyy-MM-dd",
            // valueFormat: "yyyy-MM-dd",
            display: state < 10,
            // slot: true,
            // width: "106px",
            span: 12,
            showColumn: false,
            hide: true,
          },

          {
            label: "总时长(小时)",
            prop: "hour",
            type: "number",
            display: state < 10 && way == 0,
            rules: [
              { validator: hour, trigger: "blur", required: this.isRequired },
            ],
            span: 8,
            labelWidth: "110",
            showColumn: false,
            labelWidth: "110",
            hide: true,
          },

          {
            label: "总时长(天)",
            labelWidth: "100",
            prop: "fate",
            labelWidth: "100",
            type: "number",
            rules: [
              { validator: hour, trigger: "blur", required: this.isRequired },
            ],
            span: 8,
            display: way == 1 && state < 10 && !this.showDuration,
            showColumn: false,
            hide: true,
          },

          {
            label: "课程名称",
            prop: "post",
            span: 8,
            search: true,
            slot: true,
            display: state < 10 || state == 14,
            width: "120px",
            rules: [
              {
                required: this.isRequired,
                message: "请输入课程名称",
                trigger: "blur",
              },
            ],
          },

          {
            label: "授课类别",
            display: state < 10,
            prop: "category",
            props: {
              label: "label",
              value: "label",
            },
            search: true,
            type: "select",
            dicUrl: `dictionaries/type/schedule.category`,
            rules: [
              {
                required: this.isRequired,
                message: "请选择授课类别",
                trigger: "blur",
              },
            ],
            span: 8,
          },
          {
            label: "授课城市",
            slot: true,
            prop: "city",
            filterable: true,
            type: "cascader",
            props: {
              label: "value",
              value: "value",
            },
            span: 8,
            rules: [
              {
                required: true,
                message: "请选择授课城市",
                trigger: "blur",
              },
            ],
            // dicData: this.cities,
            dicUrl: "getCity",
            checkStrictly: true,
            display: state < 10 || state == 12,
            // lazy: true,
            // async lazyLoad(node, resolve) {
            //   const items = (
            //     await this.$http.get("getCity", {
            //       params: {
            //         parent: node.path.join("-"),
            //       },
            //     })
            //   ).data;
            //   if (node.level >= 2) {
            //     items.map((v) => {
            //       v.leaf = true;
            //     });
            //   }
            //   resolve(items);
            // },
          },
          {
            label: "授课城市",
            search: true,
            prop: "searchCity",
            display: false,
            hide: true,
            span: 8,
          },
          // {
          //   label: "授课地址",
          //   display: state < 10,
          //   prop: "address",
          //   placeholder: "详细地址",
          //   span: 8,
          //   hide: true,
          //   showColumn: false,
          // },
          {
            label: "银行类别",
            prop: "bank_type",
            props: {
              label: "label",
              value: "label",
            },
            type: "select",
            dicUrl: `dictionaries/type/schedule.bank_type`,
            span: 8,
            display: state < 10,
            rules: [
              {
                required: this.isRequired,
                message: "请选择银行类别",
                trigger: "blur",
              },
            ],
            hide: true,
            showColumn: false,
          },
          // {
          //   label: "合同编号",
          //   prop: "contract_number",
          //   span: 8,
          // },

          // {
          //   label: "合同名称",
          //   prop: "contract_name",
          //   span: 8,
          // },

          {
            label: "终端名称",
            prop: "terminal_name",
            span: 8,
            display: state < 10,
            rules: [
              {
                required: this.isRequired,
                message: "请输入终端名称",
                trigger: "blur",
              },
            ],
            hide: true,
            showColumn: false,
          },
          {
            label: "终端客户",
            prop: "terminal_customer",
            span: 8,
            rules: [
              {
                required: true,
                message: "请输入终端客户",
                trigger: "blur",
              },
            ],
            hide: true,
            showColumn: false,
            display: state == 11,
          },
          {
            label: "需求课题",
            prop: "topic",
            display: state === 11,
            showColumn: false,
            hide: true,
          },
          {
            label: "有效期限",
            prop: "valid_period",
            type: "daterange",
            startPlaceholder: "选择开始时间",
            endPlaceholder: "选择结束时间",
            valueFormat: "yyyy-MM-dd HH:mm:ss",
            rules: [
              {
                required: true,
                message: "请选择有效期限",
                trigger: "blur",
              },
            ],
            display: state === 11,
            showColumn: false,
            hide: true,
          },
          {
            label: "学员岗位",
            prop: "student_job",
            display: state < 10,
            span: 8,
            hide: true,
            showColumn: false,
            rules: [
              {
                required: this.isConfirm,
                message: "请填写学员岗位",
                trigger: "blur",
              },
            ],
          },
          {
            label: "学员人数",
            prop: "student_amount",
            type: "number",
            span: 8,
            display: this.determineField,
            showColumn: false,
            hide: true,
            rules: [
              {
                required: this.isConfirm,
                message: "请填写学员人数",
                trigger: "blur",
              },
            ],
          },
          {
            label: state == 14 ? "合同金额" : "订单总额",
            prop: "order_amount",
            type: "number",
            span: 8,
            display: this.determineField || state == 14,
            rules: [
              {
                required: true,
                message: "请输入订单总额",
                trigger: "blur",
              },
            ],
          },
          {
            label: "经纪人姓名",
            prop: "agent_name",
            type: "select",
            filterable: true,
            span: 8,
            props: {
              label: "realname",
              value: "realname",
            },
            display: state == 14,
            dicData: this.brokers,
            hide: true,
            rules: [
              {
                required: true,
                message: "请选择经纪人",
                trigger: "blur",
              },
            ],
          },

          {
            label: "讲师课酬(线下)",
            prop: "price",
            type: "number",
            span: 8,
            display: state >= 1 && state < 10 && way == 1,
            rules: [
              {
                required: this.isConfirm,
                message: "请填写讲师课酬",
                trigger: "blur",
              },
            ],
          },

          {
            label: "讲师课酬(线上)",
            prop: "onlinePrice",
            type: "number",
            span: 8,
            display: state >= 1 && state < 10 && way == 0,
            rules: [
              {
                required: this.isConfirm,
                message: "请填写讲师课酬",
                trigger: "blur",
              },
            ],
          },

          // {
          //   label: "辅导老师价格",
          //   prop: "tutor_price",
          //   type: "number",
          //   span: 8,
          //   showColumn: false,
          //   display:
          //     this.determineField &&
          //     this.user.role > 0 &&
          //     this.user.role !== 2 &&
          //     this.user.role !== 3,
          // },
          {
            label: "项目经理",
            prop: "pm",
            span: 8,
            display: this.determineField,
            showColumn: false,
            hide: true,
          },

          {
            label: "课程助教",
            prop: "assistant",
            span: 8,
            display: this.determineField,
            showColumn: false,
            hide: true,
          },
          {
            label: "项目经理手机号码",
            prop: "pm_mobile",
            span: 8,
            display: this.determineField,
            showColumn: false,
            hide: true,
            // labelWidth:150,
          },
          {
            label: "课程助教手机号码",
            prop: "assistant_mobile",
            span: 8,
            display: this.determineField,
            showColumn: false,
            hide: true,
            // labelWidth:150,
            // rules: [
            //   {
            //     trigger: "blur",
            //     validator: (rule, value, callback) => {
            //       if (!validMobile(value)) {
            //         callback(new Error("手机号码格式错误"));
            //       } else {
            //         callback();
            //       }
            //     },
            //   },
            // ],
          },
          {
            label: "讲师报价表",
            prop: "quotation",
            type: "dynamic",
            disabled: true,
            span: 24,
            hide: true,
            display: this.determineField || state == 14,
            children: {
              align: "center",
              headerAlign: "left",
              rowAdd: (done) => {
                this.$message.success("新增成功");
                done({
                  // input: "默认值",
                });
              },
              rowDel: (row, done) => {
                this.$message.success("删除成功");
                done();
              },
              column: [
                {
                  width: 200,
                  label: "标题",
                  prop: "title",
                },
                {
                  label: "金额",
                  type: "number",
                  prop: "amount",
                },
                {
                  label: "单位",
                  prop: "unit",
                },
              ],
            },
          },
          {
            label: "课件是否收集",
            prop: "isCourseware_collect",
            props: {
              label: "label",
              value: "value",
            },
            span: 8,
            type: "radio",
            dicData: [
              {
                label: "是",
                value: 1,
              },
              {
                label: "否",
                value: 2,
              },
            ],
            mock: {
              type: "dic",
            },
            display: this.determineField,
            showColumn: false,
            hide: true,
          },
          {
            label: "是否安排接送",
            prop: "is_pickup",
            props: {
              label: "label",
              value: "value",
            },
            span: 8,
            type: "radio",
            dicData: [
              {
                label: "是",
                value: 1,
              },
              {
                label: "否",
                value: 2,
              },
            ],
            mock: {
              type: "dic",
            },
            display: this.determineField,
            showColumn: false,
            hide: true,
          },
          {
            label: "课酬是否结清",
            prop: "isSettle",
            props: {
              label: "label",
              value: "value",
            },
            span: 8,
            type: "radio",
            rules: [
              {
                required: true,
                message: "请选择课酬是否结清",
                trigger: "blur",
              },
            ],
            dicData: [
              {
                label: "是",
                value: true,
              },
              {
                label: "否",
                value: false,
              },
            ],
            mock: {
              type: "dic",
            },
            display: this.determineField,
            span: 8,
            hide: true,
            showColumn: false,
          },
          {
            label: "课酬结清日期",
            prop: "settle_date",
            type: "date",
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd",
            display: this.dateField && this.isSettle,
            span: 8,
            rules: [
              {
                required: true,
                message: "请选择课酬结清日期",
                trigger: "blur",
              },
            ],
            hide: true,
            showColumn: false,
          },
          // {
          //   label: "物料是否发送",
          //   prop: "isMateriel_send",
          //   span: 12,
          //   props: {
          //     label: "label",
          //     value: "value",
          //   },
          //   type: "radio",
          //   dicData: [
          //     {
          //       label: "是",
          //       value: 1,
          //     },
          //     {
          //       label: "否",
          //       value: 2,
          //     },
          //   ],
          //   mock: {
          //     type: "dic",
          //   },
          //   display: this.determineField,
          //   showColumn: false,
          //   hide: true,
          // },
          {
            label: "上传课件",
            prop: "course_ware_urls",
            type: "upload",
            multiple: true,
            dataType: "array",
            action: "upload",
            span: 24,
            limit: 5,
            showColumn: false,
            display: state == 2 || state == 3,
            hide: true,
          },
          {
            label: state == 14 ? "合同细则" : "合同图片",
            prop: "contract",
            type: "upload",
            multiple: true,
            dataType: "array",
            listType: "picture-card",
            action: "upload",
            span: 24,
            limit: 20,
            showColumn: false,
            display: this.determineField || state == 14,
            rules: [
              {
                required: this.contractImg,
                message: "请上传合同图片",
                trigger: "blur",
              },
            ],
            hide: true,
          },
          // {
          //   label: "合同细则",
          //   prop: "contract_detail",
          //   type: "upload",
          //   dataType: "array",
          //   listType: "picture-card",
          //   action: "upload",
          //   span: 24,
          //   showColumn: false,
          //   display: state == 14,
          //   // rules: [
          //   //   {
          //   //     required: true,
          //   //     message: "请上传合同细则",
          //   //     trigger: "blur",
          //   //   },
          //   // ],
          //   hide: true,
          // },
          {
            label: "合同附件",
            prop: "file",
            type: "upload",
            loadText: "附件上传中，请稍等",
            span: 18,
            showColumn: false,
            action: "upload",
            display: this.determineField || state == 14,
            // rules: [
            //   {
            //     required: this.isContractUpload,
            //     message: "请上传合同附件",
            //     trigger: "blur",
            //   },
            // ],
            hide: true,
          },

          {
            label: "合同签订时间",
            prop: "contract_signing_time",
            type: "date",
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd",
            display: this.determineField || state == 14,
            showColumn: false,
            hide: true,
            rules: [
              {
                required: this.isConfirm,
                message: "请选择合同签订时间",
                trigger: "blur",
              },
            ],
          },
          {
            label: "确认函",
            prop: "confirmation",
            span: 12,
            type: "radio",
            dicData: [
              {
                label: "是",
                value: 1,
              },
              {
                label: "否",
                value: 2,
              },
            ],
            mock: {
              type: "dic",
            },
            display: this.determineField,
            showColumn: false,
            hide: true,
          },
          {
            label: "需求",
            prop: "demand",
            type: "textarea",
            span: 24,
            minRows: 2,
            showColumn: false,
            // rules: [
            //   {
            //     required: this.isDemand,
            //     message: "请填写需求",
            //     trigger: "blur",
            //   },
            // ],
            display: this.determineField,
            hide: true,
          },
          {
            label: "需求(图片)",
            prop: "demandImg",
            type: "upload",
            multiple: true,
            dataType: "array",
            listType: "picture-card",
            action: "upload",
            span: 24,
            limit: 20,
            // rules: [
            //   {
            //     required: this.isDemandUpload,
            //     message: "请上传需求(图片)",
            //     trigger: "blur",
            //   },
            // ],
            showColumn: false,
            display: this.determineField,
            hide: true,
          },
          {
            label: "是否申请开票",
            prop: "isApplyBill",
            props: {
              label: "label",
              value: "value",
            },
            span: 8,
            type: "radio",
            dicData: [
              {
                label: "是",
                value: true,
              },
              {
                label: "否",
                value: false,
              },
            ],
            mock: {
              type: "dic",
            },
            display: this.ImplementedField,
            showColumn: false,
            hide: true,
          },
          {
            label: "开票公司",
            prop: "billing_company",
            props: {
              label: "company",
              value: "_id",
            },
            rules: [
              {
                required: true,
                message: "请选择开票公司",
                trigger: "blur",
              },
            ],
            type: "select",
            dicData: this.billingCompanies,
            dicQuery: {
              query: {
                limit: 0,
              },
            },
            span: 12,
            display: this.InvoiceField && this.isBilling_company,
            showColumn: false,
            hide: true,
          },
          {
            label: "客户开票公司",
            filterable: true,
            prop: "customer_billing_company",
            type: "select",
            span: 12,
            props: {
              label: "company",
              value: "company",
              // res: 'contacts',
              // children: 'contacts'
            },
            dicData: this.invoices,
            display: this.InvoiceField && this.isBilling_company,
            hide: true,
            showColumn: false,
          },
          {
            label: "发票类型",
            prop: "billing_type",
            type: "radio",
            dicData: [
              {
                label: `普票(税率:${this.general_ticket}%)`,
                value: "普票",
              },
              {
                label: `专票(税率:${this.special_ticket}%)`,
                value: "专票",
              },
              {
                label: `电子普票(税率:${this.electronic_ticket}%)`,
                value: "电子普票",
              },
              {
                label: `电子专票(税率:${this.electronic_special_ticket}%)`,
                value: "电子专票",
              },
            ],
            rules: [
              {
                required: true,
                message: "请选择发票类型",
                trigger: "blur",
              },
            ],
            span: 12,
            display: this.InvoiceField && this.isBilling_company,
            showColumn: false,
            hide: true,
          },
          {
            label: "发票金额",
            prop: "invoice_amount",
            type: "number",
            span: 12,
            rules: [
              {
                required: true,
                message: "请填写发票金额",
                trigger: "blur",
              },
            ],
            display: this.InvoiceField && this.isBilling_company,
            showColumn: false,
            hide: true,
          },
          {
            label: "邮寄联系人",
            prop: "mailing_contact",
            span: 12,
            rules: [
              {
                required: true,
                message: "请填写邮寄联系人",
                trigger: "blur",
              },
            ],
            display: this.InvoiceField && this.isBilling_company,
            showColumn: false,
            hide: true,
          },
          {
            label: "邮寄联系电话",
            prop: "mailing_mobile",
            span: 12,
            rules: [
              {
                required: true,
                message: "请填写邮寄联系电话",
                trigger: "blur",
              },
            ],
            display: this.InvoiceField && this.isBilling_company,
            showColumn: false,
            hide: true,
          },
          {
            label: "邮寄地址",
            prop: "mailing_address",
            type: "textarea",
            rules: [
              {
                required: true,
                message: "请填写邮寄地址",
                trigger: "blur",
              },
            ],
            span: 12,
            minRows: 2,
            display: this.InvoiceField && this.isBilling_company,
            hide: true,
            showColumn: false,
          },
          // {
          //   label: "开票时间",
          //   prop: "billing_time",
          //   type: "datetime",
          //   span: 12,
          //   format: "yyyy-MM-dd hh:mm:ss",
          //   mock: {
          //     type: "datetime",
          //     format: "yyyy-MM-dd hh:mm:ss",
          //     now: true,
          //   },
          //   display: this.InvoiceField && this.isBilling_company,
          //   hide: true,
          //   showColumn: false,
          // },

          // {
          //   label: "课酬",
          //   prop: "classPay",
          //   type: "dynamic",
          //   display: this.determineField,
          //   children: {
          //     column: [
          //       {
          //         label: "结清日期",
          //         prop: "date",
          //         type: "datetime",
          //         span: 8,
          //         format: "yyyy-MM-dd hh:mm:ss",
          //         mock: {
          //           type: "datetime",
          //           format: "yyyy-MM-dd hh:mm:ss",
          //           now: true,
          //         },
          //       },
          //       {
          //         label: "金额",
          //         prop: "money",
          //         span: 8,
          //       },
          //       {
          //         label: "备注",
          //         prop: "info",
          //         type: "textarea",
          //       },
          //     ],
          //   },
          //   span: 16,
          //   hide: true,
          //   showColumn: false,
          // },
          {
            label: "短信通知",
            prop: "text",
            span: 8,
            formslot: true,
            labelslot: true,
            errorslot: true,
            display: this.ImplementedField,
            showColumn: false,
            hide: true,
          },
          {
            label: "满意度",
            prop: "evaluate",
            type: "select",
            props: {
              label: "label",
              value: "value",
            },
            rules: [
              {
                required: true,
                message: "请选择满意度",
                trigger: "blur",
              },
            ],
            span: 8,
            dicUrl: `dictionaries/type/schedule.satisfaction`,
            display: this.ImplementedField,
            showColumn: false,
            hide: true,
          },
          {
            label: "报销总额",
            prop: "reimbursementTotal",
            type: "number",
            span: 8,
            rules: [
              {
                required: true,
                message: "请填写报销总额",
                trigger: "blur",
              },
            ],
            display: this.ImplementedField,
            showColumn: false,
            hide: true,
          },
          {
            label: "是否报销",
            prop: "isReimbursement",
            type: "radio",
            dicData: [
              {
                label: "是",
                value: 1,
              },
              {
                label: "否",
                value: 2,
              },
            ],
            mock: {
              type: "dic",
            },
            span: 8,
            display: this.ImplementedField,
            showColumn: false,
            hide: true,
          },
          {
            label: "行程信息",
            prop: "trip_info",
            type: "textarea",
            span: 24,
            minRows: 2,
            display: this.determineField,
            hide: true,
            showColumn: false,
          },
          {
            label: "住宿地点",
            prop: "stays_place",
            type: "textarea",
            span: 24,
            minRows: 2,
            display: this.determineField,
            hide: true,
            showColumn: false,
          },

          {
            label: "备注",
            prop: "info",
            type: "textarea",
            span: 24,
            minRows: 2,
            hide: true,
            display: state < 10 || state == 14,
            showColumn: false,
          },
          {
            label: state < 12 ? "注意事项" : "事项内容",
            prop: "matter",
            type: "textarea",
            span: 24,
            minRows: 2,
            showColumn: false,
            hide: true,
            display: state < 14,
            // rules: [
            //   {
            //     required: true,
            //     message: "请输入事项内容",
            //     trigger: "blur",
            //   },
            // ],
          },
          {
            label: "创建日期",
            prop: "createdAt",
            type: "datetime",
            format: "yyyy-MM-dd hh:mm:ss",
            display: state >= 1 && state < 10 && !this.isAdd,
            showColumn: false,
            hide: true,
            disabled: true,
          },

          {
            label: "回款记录",
            prop: "payBack",
            type: "dynamic",
            span: 16,
            hide: true,
            display: (this.ImplementedField && this.isTeacher) || state == 14,
            children: {
              column: [
                {
                  label: "回款日期",
                  prop: "date",
                  type: "datetime",
                  span: 8,
                  rules: [
                    {
                      required: true,
                      message: "请选择回款日期",
                      trigger: "blur",
                    },
                  ],
                  format: "yyyy-MM-dd hh:mm:ss",
                  mock: {
                    type: "datetime",
                    format: "yyyy-MM-dd hh:mm:ss",
                    now: true,
                  },
                  rules: [
                    {
                      required: true,
                      message: "请选择回款日期",
                      trigger: "blur",
                    },
                  ],
                },
                {
                  label: "回款金额",
                  prop: "money",
                  rules: [
                    {
                      required: true,
                      message: "请填写回款金额",
                      trigger: "blur",
                    },
                  ],
                },
                {
                  label: "备注",
                  prop: "info",
                  type: "textarea",
                },
              ],
            },
          },

          {
            label: "回款凭证",
            prop: "collection_voucher",
            type: "upload",
            multiple: true,
            dataType: "array",
            listType: "picture-card",
            action: "upload",
            span: 24,
            limit: 20,
            showColumn: false,
            display: this.ImplementedField && this.isTeacher,
            showColumn: false,
            hide: true,
          },

          {
            label: "培训图片",
            prop: "training_photos",
            type: "upload",
            multiple: true,
            dataType: "array",
            listType: "picture-card",
            action: "upload",
            span: 24,
            showColumn: false,
            hide: true,
            display: this.ImplementedField,
          },

          {
            label: "负责人",
            prop: "user",
            slot: true,
            display: false,
          },
        ],
      };
    },

    // 已实施状态下要显示的字段
    ImplementedField() {
      const state = this.obj.status;
      return state >= 3 && state < 10;
    },

    isVacation() {
      let start = false;
      if (this.isTeacher && this.obj.status !== 13) {
        start = true;
      }
      return start;
    },

    InvoiceField() {
      const state = this.obj.status;
      return [3, 14].includes(state);
    },

    dateField() {
      const state = this.obj.status;
      return [2, 3, 14].includes(state);
    },

    //合同图片在确定、已实施状态下必填
    contractImg() {
      const state = this.obj.status;
      return state >= 2 && state < 10;
    },

    // 确定状态下要显示的字段
    determineField() {
      const state = this.obj.status;
      return state >= 2 && state < 10;
    },
    isTeacher() {
      const role =
        (this.user && this.user.role == 2) || this.user.role == 3
          ? false
          : true;
      return role;
    },
    showStatus() {
      if (this.user.role == 3 || this.user.role == 2) {
        return true;
      }
      return this.isShowUi;
    },

    // 判断是否选了开票公司
    // isBilling_company() {
    //   const bool =
    //     this.obj.billing_company && this.obj.billing_company.length > 0
    //       ? true
    //       : false;
    //   return bool;
    // },

    isBilling_company() {
      const bool = this.obj.isApplyBill;
      return bool;
    },

    isSettle() {
      const bool = this.obj.isSettle;
      return bool;
    },

    seheduleTime() {
      const times = this.schedules.map((v) => {
        let times = v.times.map((v) => {
          return this.day(v);
        });
        let timsStr = "";
        times.forEach((v) => {
          timsStr += v;
        });
        return timsStr;
      });
      let time = "";
      for (const item of times) {
        time += item;
      }
      return time;
    },

    assistantUser() {
      return this.teacher.assistant_user?.username;
    },
  },

  methods: {
    setDate(item) {
      switch (item.value) {
        case "now":
          this.value = dayjs().toDate();
          break;
        case "prev":
          this.value = dayjs(this.value)
            .subtract(1, "month")
            .startOf("m")
            .toDate();
          break;
        case "next":
          this.value = dayjs(this.value).add(1, "month").startOf("m").toDate();
          break;
      }
    },
    getFileName(filename) {
      return `${this.obj.times[0] || ""}-${this.obj.teacher}-${filename}`;
    },
    uploadDone(res, done, loading, column) {
      if (column.prop == "course_ware_urls") {
        let list = this.obj.course_ware || [];
        list.push({
          name: res.name,
          url: res.url,
          uploadTime: new Date(),
        });
        this.$set(this.obj, "course_ware", list);
      }
      done();
    },

    uploadDelete(file, column) {
      if (column.prop == "course_ware_urls") {
        const index = this.obj.course_ware.findIndex((v) => v.url === file.url);
        if (index > -1) {
          this.obj.course_ware.splice(index, 1);
        }
      }
    },

    uploadExceed(limit, files, fileList, column) {
      if (files.length > limit) {
        this.$message.error(`${column.label}超出上传数量限制`);
      }
    },
    // emptytChange() {
    //   this.obj.status = 1;
    //   this.obj.way = 1;
    //   console.log(this.teacherId);
    // },
    async remind(teacher) {
      const res = await this.$http.get(`schedules/eveningClass/${teacher}`);
      this.eveningClass = res.data;
      // const teacher = this.schedules.filter((t) => {
      //   return t.teacher && t.teacher._id == this.teacherId;
      // });
      // if (teacher.length > 0) {
      //   var teacherTimes = new Array();
      //   teacher.forEach((v) => {
      //     v.nights &&
      //       v.nights.map((c) => {
      //         teacherTimes.push(c);
      //       });
      //   });
      // }
      // const nights = this.$filters.formatDateT(
      //   teacherTimes && teacherTimes.join(",")
      // );
      // return nights && nights.length > 0 ? nights : "";
      // return res
    },
    colour(item) {
      // console.log(item);
      let color = null;
      if (item.status == 1) {
        color = "success";
      } else if (item.status == 2) {
        color = "";
      } else if (item.status == 3 && !item.isSettle) {
        color = "info";
      } else if (item.status == 12) {
        color = "warning";
      } else if (item.status == 3 && item.isSettle) {
        color = "green";
      } else {
        color = "danger";
      }

      return color;

    },
    category() {
      if (this.user.role == 2 || this.user.role == 3) {
        this.status = [
          {
            label: "讲师私事",
            value: 12,
          },
        ];
      } else if (this.isAdd && (this.user.role !== 2 || this.user.role !== 3)) {
        this.status = [
          {
            label: "报备",
            value: 11,
          },
          {
            label: "预定",
            value: 1,
          },
          {
            label: "确定",
            value: 2,
          },
          {
            label: "讲师私事",
            value: 12,
          },
          {
            label: "节假日",
            value: 13,
          },
          {
            label: "其他业务",
            value: 14,
          },
        ];
      } else {
        this.status = [
          {
            label: "预定",
            value: 1,
          },
          {
            label: "确定",
            value: 2,
          },
          {
            label: "已实施",
            value: 3,
          },
          {
            label: "讲师私事",
            value: 12,
          },
          {
            label: "节假日",
            value: 13,
          },
          {
            label: "其他业务",
            value: 14,
          },
        ];
      }
    },
    refresh() {
      this.fetch();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.fileList = file.contract;
      this.dialogImg = true;
    },
    isEdit(item) {

      if(this.user.role===0){
        return true;
      }else if(this.user.role===1){
        return item.user?._id == this.user._id
      }else if(this.user.role===5){
       return this.user.userInfo.includes(item.user._id) ||   item.user?._id == this.user._id
      }else if(this.user.role===2){
       return true;
      }else if(this.user.role===8){
       return true;
      }
      else {
        return false;
      }
    },
    isShow(item) {
      return (item.user?._id == this.user._id &&
        item.status == 3 &&
        !item.isApplyBill) ||
        ([0, 8].includes(this.user.role) &&
          item.status == 3 &&
          !item.isApplyBill)
        ? true
        : false;
    },
    all() {
      this.teacherId = "";
    },
    handleNodeClick(data) {
      if (!data._id) {
        return false;
      }
      this.teacherId = data._id;
    },

    getpath(node) {
      let path;

      while (node && node.level == 0) { }
    },

    async findteachers(path) {
      const { data } = await this.$http.get("teachers/path", {
        params: {
          path,
        },
      });

      return data;
    },

    async loadNode(node, resolve) {
      if (node.isLeaf) {
        return resolve([]);
      }
      if (node?.data?.children?.length) {
        return resolve(node.data.children);
      } else {
        if (node.level == 0) {
          return resolve([]);
        }
        const path = findLabels(node);
        // console.log("fetch tears",node,path)
        const list = await this.findteachers(path);
        return resolve(list);
      }
      // resolove([])
      // if (node.childNodes.length) {
      //     return resolve(node.childNodes);
      //   }else {
      //     console.log("fetch tears")
      //     return resolve([])
      //   }
    },

    show(item) {
      this.currentItems = [item];
      this.form.contract = item.contract;
      this.showPopup = true;
      this.remark = item.teacher_remark || "";
    },
    preview(file, column, done) {
      const ext = file.url.split(".").pop();
      if (["jpg", "jpeg", "png", "gif"].includes(ext)) {
        done();
      } else {
        window.open(file.url);
      }
    },
    getStateName(v) {
      const states = {
        1: "预定",
        2: "确定",
        3: "已实施",
        11: "报备",
        12: "讲师私事",
        13: "节假日",
      };
      return states[v];
    },
    async doImport() {
      if (this.importData.length > 0) {
        const loading = this.$loading({
          lock: true,
          text: "导入中，请稍后。请勿关闭刷新页面",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        await this.$http.post("schedules/import", this.importData);
        loading.close();
        this.fetch();
        this.$message.success("导入成功");
      } else {
        this.$message.error("上传文件为空");
      }
    },
    async parseItems(e) {
      const file = e.target.files[0];
      const { results } = await this.$Export.xlsx(file);
      this.$message.success("上传成功,请导入");
      const items = results.map((v) => {
        return {
          teacher: v["讲师"],
          times: v["上课日期"],
          way: v["授课方式"],
          fate: Number(v["授课总时长（天）"]),
          hour: Number(v["授课总时长（小时）"]),
          customer: v["客户名称"],
          city: v["授课城市"],
          address: v["授课详细地址"],
          post: v["课程名称"],
          category: v["授课类别"],
          bank_type: v["银行类别"],
          terminal_name: v["终端名称"],
          student_job: v["学员岗位"],
          price: v["讲师课酬"],
          info: v["备注"],
          // matter: v["注意事项"],
          user: v["创建人"],
        };
      });
      this.importData = items;
    },
    rolePermission() {
      // 如果用户角色为2=讲师，只能看自己的
      if (!this.more) {
        this.permission = this._.get(this.user, "role") === 2 ? 2 : 1;
      }
    },
    handleClose(done) {
      this.fetch();
      done();
    },
    async search(where, done) {
      done();
      if (where.post) {
        this.searchName = { $regex: where.post };
      } else {
        this.searchName = null;
      }
      if (where.customer) {
        this.searchCustomer = where.customer;
      } else {
        this.searchCustomer = null;
      }
      if (where.way == 0 || where.way == 1) {
        this.searchWay = where.way;
      } else {
        this.searchWay = null;
      }
      if (where.category) {
        this.searchCategory = where.category;
      } else {
        this.searchCategory = null;
      }
      if (where.searchCity) {
        this.searchCity = { $in: [where.searchCity] };
      } else {
        this.searchCity = null;
      }
      this.fetch();
    },
    exportSchedule() {
      const startMonth = dayjs(this.value).startOf("month").toDate();
      const endMonth = dayjs(this.value).endOf("month").toDate();
      this.exportForm.isMateriel_send = 0;
      this.exportForm.isCourseware_send = 0;
      this.$set(this.exportForm.daterange, 0, startMonth);
      this.$set(this.exportForm.daterange, 1, endMonth);
      this.$set(this.exportForm.tree2, 0, 1);
      this.$set(this.exportForm.tree2, 1, 2);
      this.$set(this.exportForm.tree2, 2, 3);
      this.dialogExport = true;
    },
    async exportSubmit(row, done) {
      row.user = [this.user._id];
      if (this.user.role == 7) {
        row.teacher = this.user.teachers;
      }
      row.role = this.user.role;
      const res = await this.$http.post("/schedules/export/", row, {
        responseType: "arraybuffer",
      });
      const link = document.createElement("a");
      let blob = new Blob([res.data], { type: "application/x-xls" });
      link.href = URL.createObjectURL(blob);
      const title = `档期数据-${dayjs().format("YYYY-MM-DD")}`;
      link.download = `${title}.xlsx`;
      link.click();
      document.body.appendChild(link);
      this.$message.success("导出成功");
      this.dialogExport = false;
      done();
    },
    async expenseUpdate(row, index, done) {
      this.$clear(this.formRow, true);
      this.formRow.reimbursement[index] = row;
      let res = await this.$http.put(
        `schedules/${this.formRow._id}`,
        this.formRow
      );
      this.formRow = res.data;
      this.$message.success("更新成功");
      this.fetch();
      done();
    },
    async expenseRemove(row, done) {
      this.$clear(this.formRow);
      try {
        await this.$confirm("是否确认删除？");
      } catch (e) {
        return;
      }
      this.formRow.reimbursement.splice(row.$index, 1);
      await this.$http.put(`schedules/${this.formRow._id}`, this.formRow);
      this.$message.success("删除成功");
    },
    async expenseCreate(row, done) {
      this.$clear(this.formRow, true);
      this.formRow.reimbursement.push(row);
      await this.$http.put(`schedules/${this.formRow._id}`, this.formRow);
      this.$message.success("提交成功");
      this.fetch();
      done();
    },
    Reimbursement(row) {
      this.formRow = row;
      this.dialogExpense = true;
    },

    fetchFate(v) {
      this.times = [];
      for (const item of v) {
        var selectTime = dayjs(item).format("YYYY-MM-DD");
        this.times.push({
          label: selectTime,
          value: dayjs(item).format("YYYY-MM-DD"),
        });
      }
      if (v.length && !this.showDuration) {
        this.obj.fate = v.length;
        this.days = v.length;
      } else {
        // this.obj.fate = 0;
        delete this.obj.fate;
      }
    },

    async fetchContact(id) {
      const customer = (await this.$http.get(`customers/${id}`)).data;
      const field = this.scheduleOption.column.find(
        (v) => v.prop === "contact"
      );
      const options =
        customer.contacts && customer.contacts.length > 0
          ? customer.contacts
          : [{ name: "" }];
      // field.dicData = options;
      this.contacts = options;
      // this.fetch();
    },

    async fetchInvoice(id) {
      const customer = (await this.$http.get(`customers/${id}`)).data;
      const field = this.scheduleOption.column.find(
        (v) => v.prop === "customer_billing_company"
      );
      const options =
        customer.invoices && customer.invoices.length > 0
          ? customer.invoices
          : [{ name: "" }];
      this.invoices = options;
    },

    // 根据当前选择的开票公司返回它的税率
    async fetchInvoiceType(id) {
      const invoice = (await this.$http.get(`invoices/${id}`)).data;
      if (invoice.general_ticket) {
        this.general_ticket = invoice.general_ticket;
      } else {
        this.general_ticket = 0;
      }
      if (invoice.special_ticket) {
        this.special_ticket = invoice.special_ticket;
      } else {
        this.special_ticket = 0;
      }
      if (invoice.electronic_ticket) {
        this.electronic_ticket = invoice.electronic_ticket;
      } else {
        this.electronic_ticket = 0;
      }
      if (invoice.electronic_special_ticket) {
        this.electronic_special_ticket = invoice.electronic_special_ticket;
      } else {
        this.electronic_special_ticket = 0;
      }
    },

    async fetchAmount(v) {
      if (!this.isAmount) {
        this.isAmount = true;
      } else {
        const rate =
          v == "专票"
            ? this.special_ticket / 100
            : v == "普票"
              ? this.general_ticket / 100
              : v == "电子普票"
                ? this.electronic_ticket / 100
                : this.electronic_special_ticket / 100;
        this.obj.invoice_amount = (
          this.obj.order_amount +
          this.obj.order_amount * rate
        ).toFixed(2);
      }
    },

    // 返回讲师报价表
    async fetchQuotation(id) {
      if (!id) return false;
      const teacher = (await this.$http.get(`teachers/${id}`)).data;
      this.obj.quotation = teacher.quotation;
    },

    // 返回讲师课酬
    async fetchPay(id) {
      // this.teacherId = id;
      if (!id) return false;
      const teacher = (await this.$http.get(`teachers/${id}`)).data;
      if (this.obj.way == 1) {
        if (id) {
          this.obj.price = teacher.price;
        }
      } else {
        this.obj.onlinePrice = teacher.onlinePrice;
      }
      this.obj.quotation = teacher.quotation;
    },

    async fetchLecturer(id) {
      this.lecturer = (await this.$http.get(`teachers/${id}`)).data;

      this.$nextTick(() => {
        // this.setTextTargetFlow()
      });
    },

    setTextTargetFlow() {
      const contentElement = document.querySelector(".tip_content");
      console.log(contentElement.scrollHeight, contentElement.offsetHeight);
      this.isTextOverflow =
        contentElement &&
        contentElement.scrollHeight > contentElement.offsetHeight;
    },
    async fetchtypes() {
      const res = await this.$http.get("teachers/type");
      this.teacherList = res.data;
    },
    setTeacher(val) {
      if (val) {
        console.log("set val teacher", val);
        localStorage.setItem("teacherId", val);
      }
    },

    // 返回所有讲师
    async fetchTeacher() {
      const res = await this.$http.get("teachers/option");
      this.teacherOption = res.data;
      const ret = await this.$http.get("teachers/list");

      let save_teacher = localStorage.getItem("teacherId");

      // this.teacherList = ret.data;
      if ((this.user && this.user.role == 2) || this.user.role == 3) {
        if (save_teacher) {
          this.teacherId = save_teacher;
        } else {
          this.teacherId = this.user.teacher;
        }
      } else if (!this.more) {
        if (save_teacher) {
          this.teacherId = save_teacher;
        } else {
          this.teacherId = this.teacherOption?.[0]?.id;
        }
      }
    },

    async fetchCustomer(id) {
      const params = {};
      if (id) {
        params.q = id;
      }
      const res = await this.$http.get("customers/option", {
        params,
      });
      this.customerOption = res.data;
    },

    async fetchBilling() {
      this.billingCompanies = (await this.$http.get("invoices/option")).data;
    },

    async fetchCity() {
      this.cities = (await this.$http.get("getCity")).data;
    },

    async remove(row) {
      try {
        await this.$confirm("是否确认删除？");
      } catch (e) {
        return;
      }
      await this.$http.delete(`schedules/${row._id}`);
      this.$message.success("删除成功");
      this.fetch();
    },

    async removeCalendar(id) {
      try {
        await this.$confirm("是否确认删除？");
      } catch (e) {
        return;
      }
      await this.$http.delete(`schedules/${id}`);
      this.dialogVisible = false;
      this.showPopup = false;
      this.$message.success("删除成功");
      this.fetch(this.teacherId);
    },

    day(val) {
      return dayjs(val).format("YYYY-MM-DD");
    },
    dayTime(val) {
      return dayjs(val).format("YYYY-MM-DD HH:mm");
    },
    time(val) {
      return dayjs(val).format("MM-DD");
    },

    async changPage({ pageSize, currentPage }) {
      this.query.page = currentPage;
      this.query.limit = pageSize;
      this.fetch();
    },

    async send(row) {
      try {
        const res = await this.$http.post("sms", row);
        if (!row.isSendSMS) {
          // this.$clear(row, true);
          row.isSendSMS = true;
          await this.$http.post(`schedules/isSendSms/${row._id}`);
        }
        this.$message.success("发送成功");
      } catch (e) {
        return;
      }
    },
    async fetchSchedule() {
      const scheduleId = this.$route.query.scheduleId;
      this.userId = this.$route.query.userId;
      this.payBack_type = this.$route.query.payBack_type;
      this.to_status = this.$route.query.status;
      this.customerId = this.$route.query.customerId;
      this.cooperateDate = this.$route.query.cooperateDate;
      if (this.customerId) {
        this.isCalendar = false;
      }
      this.more = this.$route.query.more;
      if (this.more) {
        this.teacherId = null;
        this.isCalendar = false;
        this.scheduleState = 2;
        if (this.user.role !== 0) {
          this.permission = 2;
        }
      }
      if (scheduleId) {
        this.isCalendar = false;
        const res = await this.$http.get(`schedules/${scheduleId}`);
        const schedule = res.data;
        this.scheduleId = schedule._id;
      }
    },
    async repeatTip(times) {
      let where = {};
      if (this.obj.teacher) {
        where.times = times.slice(-1);
        where.teacher = this.obj.teacher;
        const res = await this.$http.post("schedules/repratTip", where);
        const tipText = res.data;
        if (tipText) {
          this.$message.warning(tipText);
        }
      }
    },
    async fetchUser() {
      const res = await this.$http.get(`users`, {
        params: {
          query: {
            limit: 0,
            where: {
              role: { $in: [1, 5] },
            },
          },
        },
      });

      if (this.user.role == 5) {
        const leader = (await this.$http.get(`users/${this.user._id}`)).data;
        this.groups = leader.userInfo?.map((v) => {
          return {
            value: v._id,
            label: v.realname,
          };
        });
        this.members = this.groups.map((v) => {
          return v.value;
        });
      }

      const users = res.data.data;
      const fields = this.scheduleOption.column.find(
        (v) => v.prop === "agent_name"
      );
      this.brokers = users;
    },

    async fetchQueryTime() { },

    // async fetchReport() {
    //   const res = await this.$http.post(`teachers/annual_reports/${this.user.teacher}`, {
    //     year: this.value,
    //   });
    // },

    async fetch() {
      // Object.assign(this.query.where, where);
      const startMonth = dayjs(this.value)
        .startOf("month")
        .subtract(6, "day")
        .toDate();
      const endMonth = dayjs(this.value).endOf("month").add(15, "day").toDate();
      let where = {};
      // console.log({
      //   teacher:this.teacherId,
      //   scheduleId:this.scheduleId
      // },3333)
      if (this.scheduleId) {
        where._id = this.scheduleId;
        this.teacherId = null;
      }
      if (this.userId) {
        this.teacherId = null;
        this.isCalendar = false;
        where.user = this.userId;
      }
      if (this.payBack_type) {
        // this.teacherId = null;
        this.isCalendar = false;
        if ([1, 4].includes(this.user.role)) {
          where.user = this.user._id;
        } else if ([5].includes(this.user.role)) {
          where.user = {
            $nin: [this.user._id, ...this.user.userInfo],
          };
        }
      }

      if (this.to_status) {
        if (this.to_status == "determine") {
          where.status = 2;
        } else if (this.to_status == "reservation") {
          where.status = 1;
        }
        this.teacherId = null;
        this.isCalendar = false;
        where.times = {
          $gte: dayjs().toDate(),
        };
        if (this.user.role == 5) {
          where.user = { $in: [...this.user.userInfo, this.user._id] };
        } else if ([1, 4].includes(this.user.role)) {
          where.user = this.user._id;
        }
      }
      if (this.customerId) {
        where.customer = this.customerId;
        this.teacherId = null;
        let from = null;
        let to = null;
        from = dayjs().startOf("year").toDate();
        to = dayjs().endOf("year").toDate();

        if (this.cooperateDate == "year") {
          where.contract_signing_time = {
            $ne: null,
            $exists: true,
            $gte: from,
            $lte: to,
          };
        } else {
          where.contract_signing_time = { $ne: null, $exists: true };
        }

        if (this.totalYear) {
          where.contract_signing_time = {
            $ne: null,
            $exists: true,
            $gte: dayjs(this.totalYear).startOf("year").toDate(),
            $lte: dayjs(this.totalYear).endOf("year").toDate(),
          };
        }
      }

      // console.log({
      //   teacher:this.teacherId
      // },11111)

      if (!this.teacherId && this.user.role == 7) {
        where.teacher = { $in: this.user.teachers };
      }
      if (!this.to_status) {
        where.status = {
          $not: { $in: [11, 14] },
        };
      }
      if (this.scheduleState !== 0) {
        where.status = this.scheduleState;
      }
      if (!this.isCalendar && this.searchName) {
        where.post = this.searchName;
      }
      if (!this.isCalendar && this.searchCustomer) {
        where.customer = this.searchCustomer;
      }
      if (!this.isCalendar && (this.searchWay == 0 || this.searchWay == 1)) {
        where.way = this.searchWay;
      }
      if (this.searchCategory) {
        where.category = this.searchCategory;
      }
      if (this.searchCity) {
        where.city = this.searchCity;
      }
      // 首次进来不用fetch
      if (this.teacherId === " ") {
        return;
      }
      if (this.teacherId && !this.scheduleId && !this.more) {
        where.teacher = this.teacherId;
      }

      // if([2,3].includes(this.user.role)){
      //   if(this.){

      //   }

      // }

      if (this.permission == 3) {
        where.user = { $in: this.members };
      }
      if (this.agentId) {
        where.user = this.agentId;
      }

      if (
        this.permission === 2 &&
        this.user.role !== 2 &&
        this.user.role !== 3
      ) {
        where.user = this.user._id;
        this.query.page = 1;
      }

      // 工作台点了本日、本周、本月跳转查询条件
      if (this.date) {
        this.teacherId = null;
        this.isCalendar = false;
        if ([1, 4].includes(this.user.role)) {
          where.user = this.user._id;
        } else if (this.user.role == 5) {
          where.user = { $in: [...this.user.userInfo, this.user._id] };
        }
        if (this.date == "month") {
          where.contract_signing_time = {
            $gte: this.dayjs().startOf("month").toDate(),
            $lte: this.dayjs().endOf("month").toDate(),
          };
        } else if (this.date == "week") {
          where.contract_signing_time = {
            $gte: this.dayjs().startOf("week").add(1, "day").toDate(),
            $lte: this.dayjs().endOf("week").add(1, "day").toDate(),
          };
        } else {
          where.contract_signing_time = {
            $gte: this.dayjs().startOf("day").toDate(),
            $lte: this.dayjs().endOf("day").toDate(),
          };
        }
      }

      if (this.isCalendar) {
        where.times = { $gte: startMonth, $lte: endMonth };
        delete this.query.page;
        delete this.query.limit;
      } else {
        const startMonth = dayjs(this.dates[0]).startOf("month").toDate();
        const endMonth = dayjs(this.dates[1]).endOf("month").toDate();
        // const year = Number(dayjs(new Date()).format("YYYY"));
        // const month = Number(dayjs(new Date()).format("MM")) - 1;
        // const day = Number(dayjs(new Date()).format("DD"));
        if (
          !this.isCalendar &&
          !this.date &&
          !this.customerId &&
          !this.to_status
        ) {
          where.times = { $gte: startMonth, $lte: endMonth };
        }
      }
      this.loading = true;

      // console.log({
      //   query: this.query,
      //   payBack_type: this.payBack_type,
      //   where,
      //   teacher:this.teacherId
      // },"fetch schedules");

      try {
        this.schedule_loading = true;
        const res = await this.$http.get("schedules", {
          params: {
            query: {
              ...this.query,
              payBack_type: this.payBack_type,
              where,
            },
          },
        });
        this.page.total = res.data.total;
        this.schedules = res.data.data;
        this.loading = false;
        this.schedule_loading = false;
      } catch (e) {
        console.log(e);
      }
    },

    beforeClose() {
      this.isAdd = false;
      this.dialogVisible = false;
      this.dialogInvoicing = false;
      this.isAmount = false;
      this.isDay = false;
      if (!this.isAdd) {
        this.obj = {};
        // this.$refs.form.resetForm();
      }
    },

    filterTimes(item) {
      return (item.times ?? []).map((v) => {
        return this.$filters.formatDateY(v);
      });
    },
    citys(item) {
      let citys = item.city ?? [];
      return citys;
    },
    getHour(item) {
      if (item.way == 0) {
        return `时数：${item.hour || 0} (1天为6小时) `
      } else {
        return `天数：${item.fate || 0}天`
      }
    },
    getText(item) {
      let times = (item.times ?? []).map((v) => {
        return this.$filters.formatDateY(v);
      });

      let citys = item.city ?? [];

      let hours = 12;
      let text = `
      ${item.teacher.name}老师:
      您好 现有订您的课程，具体信息如下：
      1 培训机构名称： ${item.customer.name}
      2 培训专题： ${item.post || ""}
      3 培训人数： ${item.student_amount || 0} 人左右
      4 培训日期： ${times.join(",") || ""}  ${this.getHour(item)}
      5 学员单位： ${item.terminal_name.trim() || ""}
      6 学员岗位： ${item.student_job.trim() || ""}
      7 上课地点： ${citys.join(",") || ""}
      8 住宿地点： ${item.stays_place || ""}
      9 培训当地是否安排接送: ${item.is_pickup == 1 ? "是" : "否"}
      10 助教姓名: ${item.assistant}  助教手机号码:${item.assistant_mobile || ""
        }
      11 项目经理姓名:${item.pm} 项目经理手机号码:${item.pm_mobile || ""}
      12 需求:${item.demand || ""}
      13 行程信息:${item.trip_info || ""}
      14 备注:${item.info || ""}
      15 机构联系人:${item.contact || ""}
      16 创建人:${_.get(item, "user.realname")}
  `;
      this.copyText(text);
    },

    copyText(text) {
      if (!text) {
        return this.$message.success("文本不能为空");
      }
      navigator.clipboard.writeText(text).then(
        () => {
          this.$message.success("复制成功");
        },
        () => {
          this.$message.success("复制失败");
        }
      );
    },

    async fetchWay(way) {
      if (!this.obj.teacher) return false;

      const teacher = (await this.$http.get(`teachers/${this.obj.teacher}`))
        .data;
      if (way == 1) {
        this.obj.price = teacher.price;
      } else {
        this.obj.onlinePrice = teacher.onlinePrice;
      }
    },

    async submitRemark(item) {
      if (!this.remark) {
        return this.$message.warning("备注不能为空")
      }
      await this.$http.post(`schedules/remark/${item._id}`, {
        remark: this.remark
      });
      this.$message.success("修改备注成功")

      this.remark = ""
    },

    async invoicingSubmit(obj, done) {
      this.$clear(this.obj);
      if (this.obj.billing_company == "") {
        this.obj.billing_company = null;
      }
      if (this.obj.customer == "") {
        this.obj.customer = null;
      }
      // this.obj.times = this.obj.times.sort((a, b) => a - b);
      await this.$http.put(`schedules/${this.obj._id}`, this.obj);
      this.$message.success("更新成功");
      this.isDay = false;
      this.showPopup = false;
      this.obj = {};
      this.fetch();
      done();
      this.dialogInvoicing = false;
    },

    async submit(obj, done) {
      this.$clear(this.obj);
      console.log(obj, 'lllllll', this.obj)
      if (this.obj.way == 1) {
        this.obj.hour = 0;
        for (let i in this.obj.duration) {
          delete this.obj.duration[i].hour;
        }
        this.obj.onlinePrice = null;
      } else {
        this.obj.fate = 0;
        for (let i in this.obj.duration) {
          delete this.obj.duration[i].day;
        }
        this.obj.price = null;
      }
      let row;
      row = {
        customer: this.obj.customer,
        user: this.obj.user,
      };

      obj = Object.keys(obj).reduce((o, key) => {
        let value = obj[key];
        if (value !== undefined) {
          o[key] = value;
        }

        return o;
      }, {});

      if (!this.isAdd) {
        if (this.obj.billing_company == "") {
          this.obj.billing_company = null;
        }
        if (this.obj.customer == "") {
          this.obj.customer = null;
        }
        if (this.obj.status == 14) {
          this.obj.times = [];
          delete this.obj.duration;
        }
        if (this.obj.status == 13) {
          delete this.obj.teacher;
        }

        const status_times = this.getStatusTime(this.obj)

        if (Object.keys(status_times).length) {
          this.obj.status_times = status_times
        }
        // this.obj.times = this.obj.times.sort((a, b) => a - b);
        await this.$http.put(`schedules/${this.obj._id}`, this.obj);
        if (obj.status == 2 || obj.status == 3) {
          await this.$http.post(`customers/edit_user`, row);
        }
        this.$message.success("更新成功");
        this.isDay = false;
        this.showPopup = false;
      } else {
        if (obj.status == 14) {
          obj.times = [];
          delete obj.duration;
        }
        if (obj.status == 13) {
          delete obj.teacher;
        }
        obj.times = obj.times.sort((a, b) => a - b);
        if (obj.status == 2 || obj.status == 3) {
          await this.$http.post(`customers/edit_user`, row);
        }

        const status_times = this.getStatusTime(obj)
        if (Object.keys(status_times).length) {
          obj.status_times = status_times
        }
        console.log("edit obj", obj)
        await this.$http.post("schedules", obj);
        this.$message.success("创建成功");
      }
      // this.$refs.form.resetForm();
      this.obj = {};
      this.fetch();
      done();
      this.dialogVisible = false;
    },
    getStatusTime(item) {
      if (!item.status) {
        return {}
      }
      const obj = Object.assign({}, item.status_times || {});
      obj[item.status] = new Date()
      return obj
    },
    async update(row) {
      this.isAdd = false;
      this.times = [];
      const res = await this.$http.get(`schedules/${row._id}`);
      this.obj = res.data;
      this.obj.times = this.obj.times?.map((v) => dayjs(v).toDate()) || [];
      this.optTitle = "编辑档期";
      this.category();
      this.obj.nights =
        this.obj.nights?.map((v) => dayjs(v).format("YYYY-MM-DD")) || [];
      this.dialogVisible = true;
    },

    async invoicing(item) {
      this.times = [];
      const res = await this.$http.get(`schedules/${item._id}`);
      this.obj = res.data;
      this.obj.times = this.obj.times?.map((v) => dayjs(v).toDate()) || [];
      this.obj.isApplyBill = true;
      this.dialogInvoicing = true;
    },

    timing() {
      if (!this.isCalendar) {
        clearInterval(this.timer);
        this.timer = setInterval(() => {
          this.fetch();
        }, 15000);
      }
    },

    async changeDate() {
      // 点击上个月/今天/下个月/
      let prevBtn = document.querySelector(
        ".el-calendar__button-group .el-button-group"
      );
      this.addChange(prevBtn);
    },
    addChange(event) {
      //利用闭包
      let removeEvent = async () => {
        if (prev) {
          event.removeEventListener("click", () => removeEvent);
        }
        if (!this.isAdd) {
          const loading = this.$loading({
            lock: true,
            text: "数据加载中,请勿关闭刷新页面",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          await this.fetch();
          loading.close();
        }
      };
      let prev = event.addEventListener("click", removeEvent()) || true;
    },

    async scheduleClick(date, item) {
      if (item) {
        this.isAdd = false;
        this.times = [];
        const res = await this.$http.get(`schedules/${item._id}`);
        this.obj = res.data;
        this.obj.times = this.obj.times?.map((v) => dayjs(v).toDate()) || [];
        this.obj.nights =
          this.obj.nights?.map((v) => dayjs(v).format("YYYY-MM-DD")) || [];
        this.optTitle = "编辑档期";
        this.category();
        for (const v of item.times) {
          var selectTime = this.dayjs(v).format("YYYY-MM-DD");
          this.times.push({
            label: selectTime,
            value: this.dayjs(v).format("YYYY-MM-DD"),
          });
        }
        this.dialogVisible = true;
      } else {
        let time;
        if (date > 0) {
          time = this.dayjs(date).startOf("day").toDate();
        }
        this.isAdd = true;
        this.optTitle = "新增档期";
        this.obj = {
          status: [2, 3].includes(this.user.role) ? 12 : 1,
          way: 1,
          teacher: this.teacherId,
          times: time ? [time] : [],
        };
        this.category();
        this.dialogVisible = true;
      }
    },

    async fetchUsers() {
      const res = await this.$http.get(`users`, {
        params: {
          query: {
            limit: 0,
            where: {
              role: { $in: [1, 4, 5] },
            },
          },
        },
      });
      const users = res.data.data;
      // users.unshift({
      //   _id: "空",
      //   realname: "空",
      // });
      this.users = users;
    },
    setUi() {
      this.isShowUi = !isMobile();
    },
  },

  activated() {
    this.timing();
    this.fetch();
    this.fetchTeacher();
    this.fetchBilling();
    if (this.user.role == 2) {
      localStorage.setItem("teacherId", this.user.teacher)
    }
  },

  deactivated() {
    clearInterval(this.timer);
    this.timer = null;
  },
  async created() {
    if (this.user.role == 2) {
      localStorage.setItem("teacherId", this.user.teacher)
    }
    this.date = this.$route.query.time;
    this.totalYear = this.$route.query.totalYear;
    await this.fetchTeacher();
    this.fetchSchedule();
    this.fetchtypes();
    // this.fetchCustomer();
    // this.fetchCity();
    this.fetchUser();
    this.fetchBilling();
    this.rolePermission();
    this.fetchUsers();
    // this.fetchReport()
  },
  mounted() {
    this.setUi();
    window.addEventListener("resize", () => this.setUi());


  },
};
</script>

<style>
@media only screen and (max-width: 768px) {
  .el-dialog__body .el-form-item__label {
    width: auto !important;
  }

  .schdule_board .state-head {
    flex-wrap: wrap;
  }

  .schdule_board .import {
    width: 100%;
  }

  .schdule_board .teacher_filter {
    width: 65%;
  }

  .schdule_board .status_filter {
    width: 35%;
  }

  .schdule_board .filter_radio {
    width: 50%;
    margin-top: 10px;
    padding-left: 0 !important;
    margin-left: 0 !important;
  }

  .schdule_board .show_filter {
    width: 50%;
    margin-top: 10px;
  }

  .el-dialog__body .el-form-item__content {
    margin-left: auto !important;
  }

  .teacherList {
    display: none !important;
  }

  .night img {
    width: 13px !important;
  }

  .pl {
    padding-left: 0rem !important;
  }

  .elcalendar {
    padding: 0rem 0.4rem !important;
  }

  .import {
    display: none;
  }

  .elcalendar .state-head {
    padding: 0.6rem 0rem !important;
  }

  .schedule .el-collapse .el-col-sm-12 {
    width: 100%;
  }

  .el-popover {
    width: auto !important;
  }

  .dialog .el-col-sm-12 {
    width: 50% !important;
  }
}

@media only screen and (max-width: 667px) {
  .dialog .el-col-sm-12 {
    width: 100% !important;
  }
}

/* @media only screen and (max-width: 1280px) {
 .tabel{
   width: 96%;
 }
} */

@media only screen and (max-width: 768px) {
  .times {
    display: inline !important;
  }

  .el-message-box {
    width: auto !important;
  }

  .el-tag {
    height: 20px !important;
    line-height: 19px !important;
  }

  .el-date-picker.has-sidebar {
    width: 100% !important;
    left: 0 !important;
  }

  .el-picker-panel__sidebar {
    width: auto !important;
  }

  .el-picker-panel__body {
    margin-left: 68px !important;
  }

  .el-select-dropdown {
    width: 80% !important;
    /* left: 80% !important; */
  }

  .state {
    width: 70%;
  }

  .display-mode {
    font-size: 12px;
  }

  .el-calendar__header {
    padding: 2px 0px 10px 0px !important;
  }

  .el-radio__label {
    font-size: 12px !important;
    padding-left: 8px !important;
  }

  .el-form-item__label {
    width: auto;
  }

  .el-message {
    min-width: 60px !important;
    top: 20% !important;
  }

  .tag {
    text-overflow: initial !important;
  }

  .el-calendar-table .el-calendar-day {
    max-height: 80px !important;
    min-height: 80px !important;
    height: auto;
    padding: 0rem 0.2rem !important;
    overflow: auto;
    white-space: nowrap;
  }

  .teacher-option {
    white-space: nowrap;
    font-size: 14px;
  }

  .exportSchedule {
    display: none;
  }

  .elcalendar .query {
    display: block !important;
  }

  /* .teacher {
    position: absolute;
    top: -170%;
    left: 0;
    width: 100%;
  } */

  .el-dialog {
    width: 90% !important;
  }

  .el-dialog__wrapper {
    z-index: 999 !important;
  }

  .el-popover {
    z-index: 99799 !important;
  }

  .el-calendar__button-group {
    z-index: 995 !important;
  }

  .elcalendar .el-form-item__label {
    width: auto !important;
  }

  .el-dialog__body {
    padding: 0 10px !important;
  }

  .el-calendar__body {
    padding: 0 0px !important;
  }

  /*   
  .el-calendar__header {
    position: absolute;
    top: -46px;
    padding-bottom: 5.6rem !important;
  } */
  .avue-form__group .el-form-item__content {
    margin-left: 0 !important;
  }

  /* .clickButton{
    position: absolute;
    top: -150%;
    left: 0;
    width: 100%;
  }  */
}

/* .teacher span {
    width:10rem;
  } */

.el-dialog .avue-form__group--flex {
  padding-top: 0 !important;
}

.el-input-number.is-controls-right .el-input__inner {
  padding-right: 10px !important;
}

.el-calendar__header {
  padding: 12px 0px !important;
}

.head {
  position: relative;
  top: 0;
  width: 100%;
  z-index: 999;
}

.elcalendar td {
  padding: 0;
}

.text-primary2 {
  color: #fe5c00;
}

.clickButton {
  padding-left: 1rem;
}

.trip {
  border-radius: 0.4rem;
}

.radio {
  position: absolute;
  top: -3%;
  width: 100%;
  z-index: 999;
}

.elcalendar ::-webkit-scrollbar {
  width: 2px !important;
}

/* ::-webkit-scrollbar-track {
  border-radius: 10px !important;
  background-color: rgba(49, 49, 49, 0.1) !important;
} */

::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  background-color: rgba(49, 49, 49, 0.1) !important;
}

.el-calendar__body {
  box-shadow: 0 -1px 6px 0 rgba(0, 0, 0, 0.08);
  border-radius: 1rem;
  margin-top: 0.6rem;
}

.el-calendar-table .el-calendar-day {
  max-height: 10.2vh;
  min-height: 10.2vh;
  height: auto;
  padding: 0rem 0.2rem;
  overflow: auto;
  white-space: nowrap;
}

.tag {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.scheduleHead {
  border-radius: 6px 6px 0px 0px;
  background-color: #389bff;
  padding: 0 4px;
  color: #fff;
  height: 26px;
  line-height: 26px;

  /* color: #777; */
}

.el-backtop,
.el-calendar-table td.is-today {
  color: #409eff !important;
}

.schedulesActive {
  box-shadow: 0 -1px 6px 0 rgba(0, 0, 0, 0.04),
    0 10px 20px 0 rgba(0, 0, 0, 0.04);
  border-radius: 0.2rem;
  /* padding: 10px 10px; */
  line-height: 20px;
  font-size: 13px;
  background-color: #fff;
  color: #777;
}

.el-calendar__button-group {
  z-index: 997;
}

.text-truncate-5 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.elcalendar {
  padding: 0px 1rem 0px 0px;
}

.display-mode {
  padding-bottom: 0.6rem;
  width: 80px;
}

.query .el-radio {
  margin-right: 0;
}

.el-calendar__body thead {
  text-align: center;
}

.teacherList .el-tree {
  width: auto;
}

.teacherList .el-tree-node__expand-icon.is-leaf {
  display: none;
}

.teacherList {
  border-right: 1px solid #eee;
  /* display: none; */
  /* margin: auto; */
  display: flex;
  justify-content: center;
}

.teacherList .el-tree {
  height: 88vh;
  overflow: scroll !important;
}

.el-tree-node__content {
  position: sticky;
}

.teacherList .custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.elcalendar .el-calendar__body .el-tag {
  color: #fff !important;
  background-color: #3a87ad !important;
  border-color: #3a87ad !important;
  height: auto !important;
  line-height: 16px !important;
}

.pl {
  padding-left: 1rem;
}

.d-block {
  position: relative;
}

.night {
  position: absolute;
  right: 0;
  top: -2px;
}

.night img {
  width: 14px;
}

.contractImg .el-form-item__label {
  width: auto !important;
  font-weight: normal !important;
}

.contractImg .avue-form__row {
  padding: 0 !important;
}

.contractImg .el-upload {
  display: none;
}

.elcalendar .el-image__inner {
  border-radius: 0.4rem !important;
  border: 1px solid #ccc;
}

.refresh {
  /* float: right; */
  flex: 1;
  text-align: right;
  /* text-align: right;  
  position: relative; 
  right: 20px;   */
}

.date_top {
  align-items: center;
}

.time_str {
  white-space: nowrap;
}

.elcalendar .el-input--suffix .el-input__inner {
  padding-right: 10px !important;
}

.elcalendar .state-head {
  padding: 0rem 0rem 0.6rem 0rem;
}

.avue-crud__empty {
  padding: 6px 0px 0px 0px !important;
}

.avue-empty {
  line-height: 8px !important;
}

.date-picker_custom {}

.is-never-shadow {
  width: 100% !important;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

/* .el-calendar__header {
  display: none !important;
} */

.el-calendar-table .el-calendar-day:hover {
  background-color: transparent !important;
}

.el-table__body-wrapper .el-input.is-disabled .el-input__inner {
  color: #212529;
}

.schedule_board {
  /* width: 100%;
  justify-content: space-around;
  background-color: #fff;
  box-shadow: 0 -1px 6px 0 rgba(0, 0, 0, 0.04),
    0 10px 20px 0 rgba(0, 0, 0, 0.04);
  border-radius: 0.6rem;
  margin: 0.6rem 0rem; */
}

.schedule_content {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.schedule_content .unit-text {
  font-size: 14px;
}

.schedule_content .name {
  margin-top: 0.8rem;
  font-size: 14px;
  /* font-weight: bold; */
}

.schedule_content .count {
  margin: 0 0.2rem;
  color: #409eff;
  font-size: 26px;
}

.schedule_calendar .el-calendar__header {
  display: none;
}

.date-picker_custom .el-picker-panel__body {
  margin-left: 0 !important;
}

.flex-column {
  flex-direction: column;
}

/* 处理响应式 */

@media only screen and (max-width: 1300px) {
  .schdule_board .state-head {
    flex-wrap: wrap;
  }

  .schdule_board .import {
    width: 100%;
  }

  .schdule_board .teacher_filter {
    width: 65%;
  }

  .schdule_board .status_filter {
    width: 35%;
  }

  .schdule_board .filter_radio {
    width: 50%;
    margin-top: 10px;
    padding-left: 0 !important;
    margin-left: 0 !important;
  }

  .schdule_board .show_filter {
    width: 50%;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 1100px) {
  .teacherList {
    display: none;
  }

  .ca-list {
    width: 100% !important;
  }
}

.elcalendar .el-calendar__body .holiday_tag.el-tag {
  background: #dec674 !important;
  border-color: #dec674 !important;
}
</style>
