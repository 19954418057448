import Vue from "vue";
import * as dayjs from "dayjs";
Vue.prototype.dayjs = dayjs;

var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

import _ from "lodash";
Vue.prototype._ = _;
Vue.prototype.get = _.get;

// const times = [dayjs(), dayjs().subtract(3, 'd'), dayjs().subtract(8, 'd')]
// console.log(_.mapValues(_.groupBy(times, v => v.format('YYYY-MM-01')), 'length'))

import "./plugins/axios";
import App from "./App.vue";
import "./plugins/element";
import FormBuilder from "./components/FormBuilder.vue";
import MultipleSelect from "@/components/multipleSelect.vue"
import Sort from "@/components/Sort.vue"
Vue.component("FormBuilder", FormBuilder);
Vue.component("MultipleSelect", MultipleSelect);
Vue.component("Sort", Sort);

import "./plugins/avue";
import router from "./router";
import store from "./store";
// import http from "./http";
import "./components";
import "../public/bootstrap.min.css";
import "../public/iconfont/iconfont.css";
import "../public/new_font/iconfont.css";
import "./assets/style.css";
import XLSX from "xlsx";
import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;

// 手动引入 ECharts 各模块来减小打包体积
// import {
//   CanvasRenderer
// import 'echarts/dist/echarts'
// import ECharts from 'vue-echarts'
// } from 'echarts/renderers'
// import {
//   GaugeChart
// } from 'echarts/charts'
// import {
//   GridComponent,
//   TooltipComponent
// } from 'echarts/components'

// 全局注册组件（也可以使用局部注册）
// Vue.component('v-chart', ECharts)

Vue.use(XLSX);

// 全局注册过滤器
import * as filters from "./filters";
import { mapGetters, mapState } from "vuex";
import { xlsx } from "./util";
Vue.prototype.$filters = filters;

for (let name in filters) {
  Vue.filter(name, filters[name]);
}

Vue.mixin({
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["isStaff", "isTeacher","isCustomer"]),
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return flag;
    },
  },
  methods: {
    exportCustomData(data,fields,filename="文件.xlsx"){
      xlsx(data,fields,filename)
    },

    addBehavior(params){
      this.$http.post("behaviors",{
        time:dayjs().toDate(),
        path:window.location.href,
        ...params
      })
    }
  },
});

// Vue.prototype.$http = http;
Vue.config.productionTip = false;
Vue.prototype.$clear = (obj, removeEmpty = false) =>
  Object.keys(obj)
    .filter((k) => k.includes("$") || (removeEmpty && obj[k] === ""))
    .map((k) => delete obj[k]);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
